import { connect } from 'react-redux';
import SideNav from './View';
import {
  hideSideNav,
  showSideNav
} from '../../actions';

const mapStateToProps = ({ app  }) =>
  ({
    sideNavOpen: app.sideNavOpen
  });

const mapDispatchToProps = dispatch => ({
  hideSideNav: () => dispatch(hideSideNav()),
  showSideNav: obj => dispatch(showSideNav(obj))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNav);
