import React from 'react';
import { connect } from 'react-redux';
import Dashboard from './View'
import { Step } from 'Models/Protocol/models';

const mapStateToProps =
  ({ protocols }) =>
    ({
      showCreateOrEdit: protocols.showCreateOrEdit,
      protocol: protocols.protocol,
      selectedStep: protocols.protocol.getIn(['steps', protocols.selectedStep], Step({})),
      protocolEdited: protocols.protocolEdited
    });

export default connect(mapStateToProps)(Dashboard);
