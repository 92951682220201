import { connect } from 'react-redux';
import Template from './View';
import { hideSideNav, showSideNav } from '../../modules/App/actions';

const mapStateToProps = ({ app }) =>
  ({
    loginContext: app.loginContext
  })

const mapDispatchToProps = dispatch => ({
  hideSideNav: () => dispatch(hideSideNav()),
  showSideNav: obj => dispatch(showSideNav(obj))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Template);
