import React from 'react';
import { Popup } from 'components/Dialog/index';
import { withStyles } from '@material-ui/core';
import { toggleAreYouSureModal } from 'modules/Modals/actions';
import Button from '@material-ui/core/Button';

const styles = {
  mediumDialogContent: {
    padding: 0
  },
  centered: {
    textAlign: 'center'
  },
  button: {
    margin: 40
  }
};

const AreYouSureModal =
  ({
     showAreYouSureModal,
     classes,
     callBack,
     areYouSureString,
     areYouSureTitle,
     buttonText
   }) =>
    <Popup
      title={areYouSureTitle || 'Are you Sure?'}
      toggleFn={toggleAreYouSureModal}
      open={showAreYouSureModal ? true : false}
      medium
      mediumDialogContent={classes.mediumDialogContent}
    >
      <div className={classes.centered}>
        <div>{areYouSureString || 'If you continue your changes will be lost'}</div>
        <Button
          onClick={() => toggleAreYouSureModal() && callBack()}
          variant="contained"
          color="secondary"
          className={classes.button}
        >{buttonText || 'Discard Changes and Exit'}</Button>
      </div>
    </Popup>

export default withStyles(styles)(AreYouSureModal);
