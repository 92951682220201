import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ palette }) => ({
  innerHeading: {
    color: palette.grey[900],
    fontWeight: 400,
    fontFamily: 'Prompt, Sans-serif',
    fontSize: '1.2em',
    marginBottom: 6,
    // marginTop: 6
  }
});

const Heading = ({ classes, children }) =>
  <Typography>{children}</Typography>

export default withStyles(styles)(Heading);

const InnerHeading = withStyles(styles)(
  ({ heading, classes, className }) =>
    <Typography
      variant="h3"
      className={classNames(
        classes.innerHeading,
        className
      )}
    >
      {heading}
    </Typography>
)

export {
  InnerHeading
}
