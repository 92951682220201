import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ palette }) => ({
  badge: {
    borderRadius: 0,
    '& > span': {
      borderRadius: '3px',
      fontSize: '.7em',
      padding: '2px 6px',
      height: '12px',
      boxSizing: 'border-box',
      marginTop: '46px',
      top: '-33px',
      position: 'absolute',
      display: 'flex',
      color: 'white!important',
      width: 'auto'
    },
    '&:-ms-fullscreen, :root span': { marginTop: 0 }
  },
  altCss: {
    '& > span': {
      position: 'relative',
      alignSelf: 'center',
      marginRight: 0,
      margin: '0 auto',
      fontSize: '.9em',
      fontFamily: 'Prompt',
      fontWeight: 500,
      height: 18,
      color: 'white!important',
      top: 0
    }
  },
  lightSecondary: {
    '& > span': {
      backgroundColor: palette.secondary.light
    }
  },
  invisibleBadge: {
    '& > span': {
      display: 'none'
    }
  }
});

const BadgeComponent = ({ children, classes, badgeContent, className, altCss, color = 'primary', altColor }) =>
  <Badge
    color={color}
    badgeContent={badgeContent || ''}
    className={classNames(
      classes.badge,
      className,
      {
        [classes.invisibleBadge]: (!badgeContent || badgeContent === 0),
        [classes.altCss]: altCss,
        [classes.lightSecondary]: altColor === 'lightSecondary'
      }
    )}
  >
    {children}
  </Badge>;

export default withStyles(styles)(BadgeComponent);
