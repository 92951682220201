import React from 'react';
import Dialog from 'components/Dialog/FullScreen';
import { withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import StepComponent from '../Steps';
import {
  toggleCreateOrEditProtocol,
  updateProtocol,
  reorderSteps
} from '../actions';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { NewStep } from './components';
import { maybeOutputS } from 'utils/helpers';
import Button from '@material-ui/core/Button';
import { Map } from 'immutable';
import DropList from 'react-list-drag-and-drop/lib/RLDD';
import { logEvent } from '@medapps/amplitude-transactions';
import { normalizeStepForDragging } from 'Models/Protocol/normalize';
import ActiveStep from './ActiveStep';

const styles = ({ variables, palette }) => ({
  drawerPaper: {
    backgroundColor: 'white',
    top: variables.appBarHeight * 2,
    padding: 30,
    width: variables.extraProtocolWidth + variables.navigationWidth,
    paddingBottom: 310
  },
  titleField: {
    width: 400,
    '& *:before': {
      display: 'none'
    }
  },
  published: {
    color: palette.success,
    size: 350,
    border: `1px solid ${palette.success}`
  },
  unPublished: {
    color: palette.warning.main,
    size: 350,
    border: `1px solid ${palette.warning.main}`
  }
});

const Published = ({ classes }) =>
  <Button
    variant="outlined"
    className={classes.published}
  >Protocol Published
  </Button>

const UnPublished = ({ classes }) =>
  <Button
    variant="outlined"
    className={classes.unPublished}
  >Protocol in Draft
  </Button>

export const TextFieldComponent = withStyles(styles)(({ className, classes, ...rest }) =>
  <TextField
    className={classNames(classes.titleField, className)}
    {...rest}
  />
);

export const TitleField = ({ name }) =>
  <TextFieldComponent
    value={name}
    placeholder={'New Protocol'}
    onChange={e => updateProtocol('title', e.target.value)}
  />

const CreateOrEdit =
  ({
     classes,
     showCreateOrEdit,
     protocol,
     protocolEdited,
     selectedStep
   }) => selectedStep.sections && showCreateOrEdit &&
    <Dialog
      open={showCreateOrEdit}
      title={<TitleField name={protocol.title}/>}
      subTitle={`${(protocol.steps || Map({})).size} step${maybeOutputS(protocol.steps || Map({}))}`}
      whiteBackground
      toggleFn={() => toggleCreateOrEditProtocol()}
      rightDetail={
        protocol.published &&
        <Published classes={classes}/> || <UnPublished classes={classes}/>
      }
    >
      <Drawer
        anchor="left"
        open={true}
        variant="permanent"
        classes={{
          paperAnchorLeft: classes.drawerPaper
        }}
      >
        <DropList
          onChange={reorderSteps}
          items={
            (protocol.steps || Map({}))
              .toIndexedSeq()
              .toArray()
              .map(normalizeStepForDragging)
          }
          itemRenderer={step =>
            <StepComponent
              key={step.uuid}
              step={step}
              selected={step.uuid === selectedStep.id}
            />
          }
        />
        <NewStep stepCount={protocol.steps.size}/>
      </Drawer>
      <ActiveStep
        protocolEdited={protocolEdited}
        protocol={protocol}
        selectedStep={selectedStep}
        />
    </Dialog> || null;

export default withStyles(styles)(CreateOrEdit);


