import React from 'react';
import { connect } from 'react-redux';
import CreateOrEdit from './View'

const mapStateToProps =
  ({ pages }) =>
    ({
      showCreateOrEdit: pages.showCreateOrEdit,
      page: pages.page,
      pageEdited: pages.pageEdited
    });

export default connect(mapStateToProps)(CreateOrEdit);
