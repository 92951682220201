import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Loading from 'components/Loading';
import { resetState } from 'modules/App/actions';

export default class CallbackPage extends PureComponent {
  static propTypes = {
    auth: PropTypes.shape({
      handleAuthentication: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      hash: PropTypes.string,
    }).isRequired,
  };

  state = {
    authenticated: false,
  };

  async componentDidMount() {
    const { location, auth } = this.props;
    resetState();

    if (/access_token|id_token|error/.test(location.hash)) {
      try {
        await auth.handleAuthentication(this.props);
        this.setState({ authenticated: true });
      } catch (err) {
        this.setState({ authenticated: false });
        this.props.history.push('/');
      }
    }
  }

  render() {
    return this.state.authenticated ? <Redirect to="/" /> : <Loading />;
  }
}
