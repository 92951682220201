import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import classNames from 'classnames';
import { variants } from '../CallToAction';
import parentStyles from '../styles';
import { toggleEditTimer } from '../actions';
import { Section } from 'Models/Protocol/models';
import Icon from 'components/Icons/Icon';

const styles = ({ palette, variables }) => ({
  ...parentStyles({ variables, palette }),
  stepPaper: {
    padding: 20,
    borderRadius: 0,
    // backgroundColor: palette.lightBlue,
    border: `1px solid ${palette.grey[300]}`,
    width: variables.sectionEditorWidth,
    minHeight: 50
  },
  marginRight: {
    marginRight: 10
  }
});

const DrawerComponent = withStyles(styles)(
  ({
     classes,
     content,
     style,
     id,
     order,
     duration,
     disableClick
   }) =>
    <div>
      <Button
        onClick={() => !disableClick && toggleEditTimer(Section({
          type: 'timer',
          id,
          style,
          content,
          duration,
          order
        }))}
        variant={variants.getIn([style, 'variant'], 'outlined')}
        color={variants.getIn([style, 'color'], 'secondary')}
        className={classNames(classes.sectionButton)}
      >
        <Icon icon="alarm-clock" iconType="far" color="white" className={classes.marginRight}/>
        {content || 'Click To Edit Timer...'}
      </Button>
    </div>
);

export {
  DrawerComponent
}
