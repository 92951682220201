export default ({}) => ({
  drawer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    alignItems: 'flex-end',
    backgroundColor: 'white'
  },
  marginLeft10: {
    marginLeft: 10
  },
  marginRight10: {
    marginRight: 10
  },
  saveButton: {
    width: 300,
    marginLeft: 30
  }
})
