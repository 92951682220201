import { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class LoginPage extends PureComponent {
  static propTypes = {
    auth: PropTypes.shape({
      login: PropTypes.func,
    }).isRequired,
  };

  componentDidMount() {
    this.props.auth.login();
  }

  render() {
    return null;
  }
}
