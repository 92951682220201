import React from 'react';
import Icon from 'components/Icons/Icon';
import { withStyles } from '@material-ui/core';

const styles = ({ palette }) => ({
  container: {
    display: 'flex',
    backgroundColor: 'white',
    padding: 0,
    marginRight: 50
  },
  arrow: {
    position: 'relative',
    background: palette.red,
    width: 120,
    height: 50,
    padding: '15px 60px',
    '&:after': {
      borderColor: 'transparent',
      borderLeftColor: palette.red,
      borderWidth: 25,
      left: '100%',
      top: 0,
      border: 'solid transparent',
      content: "''",
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      zIndex: 10
    },
    '&:before': {
      borderColor: 'transparent',
      borderLeftColor: 'white',
      borderWidth: 30,
      left: '100%',
      top: -5,
      border: 'solid transparent',
      content: "''",
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      zIndex: 10
    }
  }
});

const Arrow = withStyles(styles)(
  ({ icon, classes }) =>
    <div className={classes.arrow}>
      <Icon size="lg" color="white" icon={icon}/>
    </div>
);

const DrawerComponent = withStyles(styles)(
  ({ classes }) =>
    <div className={classes.container}>
      <Arrow icon="broadcast-tower"/>
      <Arrow icon="users-medical"/>
      <Arrow icon="heartbeat"/>
    </div>
)

export {
  DrawerComponent
}
