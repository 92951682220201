import React from 'react';
import CollapsibleSection from 'components/Section/CollapsibleSectionWithTable';
import InnerCollapsibleSection from 'components/Section/CollapsibleSection';
import { fetchGroupProtocols, toggleCreateOrEditProtocol, deleteProtocol } from './actions';
import Icon from 'components/Icons/Icon';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import { toggleAreYouSureModal } from '../Modals/actions';
import { maybeOutputS } from '../../utils/helpers';
import classNames from 'classnames';
import { formatDateString } from 'utils/date';
import { sort } from '../../utils/sorting';

const styles = ({ palette }) => ({
  editButton: {
    textTransform: 'none'
  },
  viewContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  marginRight: {
    marginLeft: 10
  },
  publishedIcon: {
    marginLeft: 10,
    fontSize: '1.2em'
  },
  plusIcon: {
    fontSize: '1.3em',
    marginRight: 8
  },
  published: {
    color: palette.success,
    width: 110
  },
  draft: {
    color: palette.grey[600]
  },
  marginLeft: {
    marginLeft: 10
  }
});

const Group =
  ({
     id,
     title,
     color,
     protocols,
     classes,
     searchTerm
   }) =>
    <CollapsibleSection
      key={id}
      title={title}
      data={'Some data'}
      toggleCallBack={() => !searchTerm && fetchGroupProtocols(id)}
      color={color}
      showProgress={!protocols}
      panelOpen={Boolean(searchTerm)}
      toggleFn={searchTerm ? () => {
      } : false}
      primaryAction={{
        title: 'New Protocol',
        icon: <Icon className={classes.plusIcon} icon="plus-circle" color={"primary"}/>,
        action: () => toggleCreateOrEditProtocol(null, id)
      }}
    >{
      protocols && protocols.length > 0 &&
      sort(protocols, 'title')
        .map(
          protocol =>
            <InnerCollapsibleSection
              key={protocol.id}
              hideExpander
              title={
                <Button
                  onClick={() => toggleCreateOrEditProtocol(protocol, id)}
                  color="primary"
                  className={classes.editButton}
                >{protocol.title
                }</Button>}
              subTitle={
                <div>
                  {protocol.stepCount} Step{maybeOutputS(protocol.stepCount)}
                  <Icon className={classes.marginRight} size={'xs'} icon="circle" color="darkGrey"/>
                  <span
                    className={classes.marginLeft}>{protocol.updatedAt && `Updated ${formatDateString(protocol.updatedAt)}` || `Created ${formatDateString(protocol.createdAt)}`}</span>
                </div>
              }
              open={false}
              toggleFn={() => {
              }}
              primaryAction={{
                color: 'secondary',
                title: protocol.published ?
                  <div className={classNames(classes.viewContainer, classes.published)}>
                    Published
                    <Icon className={classes.publishedIcon} icon="check-circle" color="success"/>
                  </div> :
                  <div className={classNames(classes.viewContainer, classes.draft)}>
                    In Draft
                    <Icon className={classes.publishedIcon} icon="minus-circle" color="darkGrey"/>
                  </div>
              }}
              tertiaryAction={{
                title: <div className={classes.viewContainer}>
                  View Protocol
                  <Icon className={classes.marginRight} icon="caret-right" color="primary"/></div>,
                action: () => toggleCreateOrEditProtocol(protocol, id)
              }}
              secondaryAction={{
                color: "secondary",
                title: <div className={classes.viewContainer}>
                  Delete
                  <Icon size={'1x'} className={classes.marginRight} icon="trash" color="secondary"/></div>,
                action: () => toggleAreYouSureModal(() =>
                deleteProtocol(protocol.id, id, protocol.title), 'Are you sure you want to delete this protocol? This action cannot be undone', 'Delete Protocol', 'Delete protocol')
              }}
            />
        )}
    </CollapsibleSection>

export default withStyles(styles)(Group);
