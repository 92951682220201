import auth0 from 'auth0-js';
import { logEvent } from '@medapps/amplitude-transactions';
import { createBrowserHistory } from 'history';
import { store } from 'persistentStore';
import { RESET_STATE } from '../constants';

const { dispatch } = store;

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENTID,
  REACT_APP_AUTH0_AUDIENCE
} = process.env;

const auth0Authentication = new auth0.Authentication({
  domain: REACT_APP_AUTH0_DOMAIN,
  clientID: REACT_APP_AUTH0_CLIENTID
});

export const logOutUrl = () => {
  const auth0LogOutUrl = auth0Authentication.buildLogoutUrl();
  const finalUrl = `${auth0LogOutUrl}?client_id=${REACT_APP_AUTH0_CLIENTID}&returnTo=${encodeURIComponent(`${window.location.origin}`)}`;
  return finalUrl;
}

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: REACT_APP_AUTH0_DOMAIN,
    clientID: REACT_APP_AUTH0_CLIENTID,
    redirectUri: `${window.location.origin}/callback`,
    audience: REACT_APP_AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope:'openid'
  });

  history = createBrowserHistory();

  login = () => {
    this.auth0.authorize();
  };

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          dispatch({
            type: RESET_STATE
          });
          this.setSession(authResult);
          resolve();
        } else if (err) {
          console.error(err); // eslint-disable-line
          reject();
        }
      });
    });
  }

  setSession = authResult => {
    logEvent('Login', {
      loginType: 'Dashboard',
    });
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem('access-token', authResult.accessToken);
    localStorage.setItem('id-token', authResult.idToken);
    localStorage.setItem('expires-at', expiresAt);
  };

  requestPasswordChange = email =>
    new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: process.env.REACT_APP_AUTH0_CONNECTION,
          email
        },
        err => {
          if (err) {
            return reject(err);
          }
          return resolve();
        }
      );
    });

  isAuthenticated = () => {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    this.debug('Is Authenticated', new Date().getTime() < expiresAt);
    const isAuthenticated = new Date().getTime() < expiresAt;
    return isAuthenticated;
  };
}

const auth = new Auth();

export const login = () => auth.login();
export const passwordChange = email => auth.requestPasswordChange(email)
