import React from 'react';
import logo from './logo.png';
import { withStyles } from '@material-ui/core';

const styles = ({}) => ({
  logo: {
    display: 'block',
    width: '160px',
    height: 'auto',
    margin: '-5px 0 5px'
  },
  title: {
    marginTop: -5,
    fontSize: '1.4em'
  }
});

export default withStyles(styles)(({ classes }) =>
  <React.Fragment>
    <img className={classes.logo} src={logo} alt=""/>
  </React.Fragment>
);
