export default ({ palette, variables, transitions }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 0,
    transition: transitions.create('marginLeft', {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen
    })
  },
  routeLink: {
    letterSpacing: '0.2rem',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontSize: '1em',
    fontWeight: 500,
    fontFamily: 'Prompt',
    color: palette.grey[800],
    paddingLeft: 10
  },
  selected: {
    color: palette.secondary.main,
    borderLeft: `4px solid ${palette.secondary.main}`
  },
  container: {
    flex: 1
  }
});
