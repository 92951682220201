import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import {
  Table,
  TableHead,
  TableBody,
  NA
} from 'components/Table/InnerSectionTable';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Template from 'components/Template';
import Page from 'components/Section/Page';
import TitleBar from 'components/Section/TitleBar';
import { Content } from 'components/Template/Layout';
import { fetchPages, deletePage } from './actions';
import LinearLoader from 'components/Loading/Linear';
import { formatDateString } from 'utils/date';
import Button from '@material-ui/core/Button';
import Icon from 'components/Icons/Icon';
import { toggleCreateOrEdit } from './actions';
import { toggleAreYouSureModal } from '../Modals/actions';
import CreateOrEdit from './CreateOrEdit';
import Switch from '@material-ui/core/Switch';

const styles = ({ palette }) => ({
  table: {
    borderBottom: `1px solid ${palette.grey[300]}`,
    borderTop: 'none',
    margin: 0
  },
  opaque: {
    opacity: .6
  },
  marginRight15: {
    marginRight: 15
  },
  create: {
    width: 350,
    marginRight: 30
  },
  plus: {
    margin: '-2px 7px'
  }
});

const UserTable =
  ({
     classes,
     pages,
     loading
   }) => {
    useEffect(() => {
      fetchPages();
    }, []);
    return <Template>
      <Page>
        <CreateOrEdit/>
        <TitleBar
          title="Pages"
          primaryActionButton={
            <Button
              color="primary"
              variant="outlined"
              onClick={() => toggleCreateOrEdit()}
              className={classes.create}
            >
              <Icon icon="plus-circle" color="primary" size={'1x'} className={classes.plus}/>
              Create Page
            </Button>
          }
        />
        <Content
          centered
          noPadding
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Published</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                loading &&
                <TableRow>
                  <TableCell>
                    <LinearLoader color="primary" className={classes.opaque}/>
                  </TableCell>
                </TableRow>
                ||
                (pages || []).map(page =>
                  <TableRow key={page.id}>
                    <TableCell>{page.title}</TableCell>
                    <TableCell>{<Switch checked={Boolean(page.published)} />}</TableCell>
                    <TableCell>{formatDateString(page.createdAt)}</TableCell>
                    <TableCell>{page.updatedAt ? formatDateString(page.updatedAt) : <NA/>}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        className={classes.marginRight15}
                        onClick={() => toggleCreateOrEdit(page)}
                      >
                        <Icon icon={"pencil"} color="primary" size={'1x'} inButton/>
                        Edit Page
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => toggleAreYouSureModal(() =>
                          deletePage(page.id), 'Are you sure you want to delete this Page? This action cannot be undone', 'Delete Page', 'Delete Page')
                        }
                      >
                        <Icon icon={"trash"} color="secondary" size={'1x'} inButton/>
                        Delete Page
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </Content>
      </Page>
    </Template>
  }

export default withStyles(styles)(UserTable);
