import React from 'react';
import { AddNew } from '../components';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import sectionTypes from '../sectionTypes';
import { withStyles } from '@material-ui/core';
import { addSectionToSelectedStep, removeSectionFromSelectedStep, addNewStep, saveProtocol } from '../actions';
import Icon from 'components/Icons/Icon';
import classNames from 'classnames';
import styles from '../styles';
import Button from '@material-ui/core/Button/Button';
import Drawer from '@material-ui/core/Drawer/Drawer';
import { logEvent } from '@medapps/amplitude-transactions';
import { protocolValid } from './utils';

const NewSection = withStyles(styles)(
  ({
     classes,
     sectionCount
   }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return <div>
      <AddNew
        type={'New Section'}
        color={"primary"}
        onClick={handleClick}
      />
      <Menu
        id="newSectionMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classes.menuPaper
        }}
      >
        {sectionTypes.map(
          section =>
            <MenuItem
              key={section.type}
              className={classes.sectionMenu}
              onClick={() => {
                addSectionToSelectedStep(section.type, {
                  ...section.emptySection,
                  order: sectionCount
                } || { order: sectionCount });
                return handleClose()
              }}
            >{section.name}</MenuItem>
        )}
      </Menu>
    </div>
  });

const SaveDrawer = withStyles(styles)(
  ({ classes, protocolEdited, protocol }) =>
    <Drawer
      variant="persistent"
      anchor="bottom"
      classes={{ paper: classes.drawer }}
      open={protocolEdited || !protocol.published}
    >
      <div>
        {
          protocolEdited &&
          <Button
            color="primary"
            onClick={() => {
              saveProtocol(protocol, false);
              logEvent('Protocol Draft Saved', {
                protocolName: protocol.title,
                protocolType: protocol.groupId,
              });
            }}
          >
            Save as Draft
          </Button>
        }
        <Button
          variant="contained"
          color="primary"
          className={classNames(classes.saveButton)}
          onClick={() => {
            saveProtocol(protocol, true);
            logEvent('Protocol Published', {
              protocolName: protocol.title,
              protocolType: protocol.groupId,
            });
          }}
          disabled={!protocolValid(protocol)}
        >Publish Protocol</Button>
      </div>
    </Drawer>
);

const NewStep = ({ stepCount }) =>
  <AddNew
    smaller
    type={'New Step'}
    color={"primary"}
    onClick={() => addNewStep(stepCount)}
  />

const RemoveSection = withStyles(styles)(
  ({ id, classes }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return <div>
      <div
        onClick={handleClick}
        className={classes.removeContainer}
      >
        <div className={classes.removeLine}>
          <Icon className={classes.removeIcon} size="lg" color="lightGrey" icon="times-circle"/>
          <span className={classes.removeText}>Remove</span>
        </div>
      </div>
      <Menu
        id="reallyDeleteMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classNames(classes.menuPaper, classes.deleteMenu)
        }}
      >
        <MenuItem
          className={classes.sectionMenu}
          onClick={() => {
            removeSectionFromSelectedStep(id);
            return handleClose()
          }}
        >Remove this Section
        </MenuItem>
        )}
      </Menu>
    </div>
  }
);

export {
  NewSection,
  RemoveSection,
  NewStep,
  SaveDrawer
}
