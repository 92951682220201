// Polyfills for Windows support
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { fontAwesome } from 'themes';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import muTheme from 'themes/mUTheme';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './persistentStore';
import Routes from './Routes';
import ErrorBoundary from './ErrorBoundary';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { initAmplitude } from '@medapps/amplitude-transactions';

fontAwesome.init();
const theme = createMuiTheme(muTheme);

const { REACT_APP_SENTRY_DSL, REACT_APP_RELEASE_VERSION, REACT_APP_RELEASE_ENVIRONMENT, REACT_APP_AMPLITUDE_KEY } = process.env;

initAmplitude(REACT_APP_AMPLITUDE_KEY);

if (process.env.NODE_ENV === 'production') {
  window.Raven.config(
    REACT_APP_SENTRY_DSL,
    {
      release: REACT_APP_RELEASE_VERSION,
      environment: REACT_APP_RELEASE_ENVIRONMENT
    }
  ).install();
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={theme}>
        <ErrorBoundary>
          <DndProvider backend={HTML5Backend}>
          <Routes/>
          </DndProvider>
        </ErrorBoundary>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
