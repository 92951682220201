import {
  USERS, RESET_STATE
} from 'constants/index';
import UserState from './initialState';
import { UserModel } from '../../Models/User/models';

const initialState = new UserState({});

const reducer = (state = initialState, action) =>

  action.type === USERS.SET_STATE
  && state.merge(action.state) ||

  action.type === USERS.SET_VALUE
  && state.set(action.key, action.value) ||

  action.type === USERS.RECEIVE_USERS
  && state.merge({
    users: action.users,
    loading: false
  }) ||

  action.type === USERS.SAVE_USER
  && state.merge({
    loading: false,
    user: null,
    showCreateOrEdit: false
  }) ||

  action.type === USERS.TOGGLE_CREATE_OR_EDIT
  && state.merge({
    loading: false,
    showCreateOrEdit: !state.showCreateOrEdit,
    user: action.user || new UserModel({})
  }) ||

  action.type === USERS.UPDATE_USER
  && state.setIn(['user', action.key], action.value) ||

  action.type === USERS.RESET_STATE
  && initialState ||

  action.type === RESET_STATE
  && initialState ||

  state;

export default reducer;
