export default ({ palette, spacing: { unit } }) => ({
  miniButton: {
    boxShadow: 'none!important',
    width: '22px',
    height: '22px',
    minWidth: '22px',
    minHeight: '22px',
    marginRight: unit * 22,
    '& span': {
      fontSize: '1.1em'
    }
  },
  largeIcon: {
    '& span': {
      fontSize: '1.3em'
    }
  },
  resetLargerIcon: {
    fontSize: '.8em'
  },
  noMargin: {
    margin: '0px!important'
  },
  smallSpacedIcon: {
    marginRight: unit
  },
  mediumSpacedIcon: {
    marginRight: unit * 1.5
  },
  inButton: {
    display: 'inline-block',
    margin: '-11px 6px -12px 0'
  },
  primaryColor: {
    color: palette.primary.main
  },
  secondaryColor: {
    color: palette.secondary.main
  },
  greyColor: {
    color: palette.grey[500]
  },
  darkGreyColor: {
    color: palette.grey[600]
  },
  greyBackGroundColor: {
    backgroundColor: palette.grey[500],
    color: 'white'
  },
  lightGreyColor: {
    color: palette.grey[400]
  },
  lightPrimary: {
    color: palette.primary.light
  },
  primary: {
    color: palette.primary.main
  },
  white: {
    color: 'white'
  },
  success: {
    color: palette.success
  },
  error: {
    color: palette.error.main
  },
  warning: {
    color: palette.warning[700]
  },
  spacedIcon: {
    marginRight: unit * 2
  },
  fabIcon: {
    '& > span': {
      fontSize: '.6em'
    }
  },
  listIcon: {
    fontSize: '.2em',
    margin: `2px ${unit * 2}px`
  },
  circleWhite: {
    backgroundColor: 'white',
    width: '50px!important',
    height: 50,
    padding: 10,
    borderRadius: '50%',
    margin: 0
  }
})
