import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import classNames from 'classnames';
import { variants } from '../CallToAction';
import parentStyles from '../styles';
import { toggleEditAlert } from '../actions';
import { Section } from 'Models/Protocol/models';

const styles = ({ palette, variables }) => ({
  ...parentStyles({ variables, palette }),
  stepPaper: {
    padding: 20,
    borderRadius: 0,
    // backgroundColor: palette.lightBlue,
    border: `1px solid ${palette.grey[300]}`,
    width: variables.sectionEditorWidth,
    minHeight: 50
  }
});

const DrawerComponent = withStyles(styles)(
  ({
     classes,
     content,
     style,
     id,
     order,
     disableClick
   }) =>
    <div>
      <Button
        onClick={() => !disableClick && toggleEditAlert(Section({ type: 'alert', id, style, content, order }))}
        variant={variants.getIn([style, 'variant'], 'outlined')}
        color={variants.getIn([style, 'color'], 'secondary')}
        className={classNames(classes.sectionButton)}
      >{content || 'Click To Edit Alert...'}
      </Button>
    </div>
);

export {
  DrawerComponent
}
