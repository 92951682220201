export default ({ palette, variables, transitions, breakpoints, typography, spacing: { unit } }) =>
  ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: variables.appBarHeight
    },
    profileIcon: {
      marginTop: 16
    },
    tableTitle: {
      margin: '35px 0 0 35px'
    },
    templateRoot: {
      marginLeft: variables.navigationWidth
    },
    qrButton: {
      marginTop: 3,
      marginRight: 36,
      cursor: 'pointer'
    },
    navigation: {
      display: 'flex',
      flex: 1,
      paddingLeft: '3rem',
      justifyContent: 'flex-end',
      fontSize: '1.1em',
      [breakpoints.down('md')]: {
        paddingLeft: '1.5rem'
      }
    },
    sortAccount: {
      width: '12px!important',
      cursor: 'pointer'
    },
    paddingTop: {
      paddingTop: 80
    },
    activeLink: {
      color: `${palette.secondary.main}!important`
    },
    transparentButton: {
      height: '52px',
      marginTop: '26px',
      padding: '10px 50px'
    },
    appBar: {
      backgroundColor: palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      borderBottom: `1px solid ${palette.primary.dark}`,
      height: variables.appBarHeight,
      left: variables.navigationWidth,
      top: 0,
      position: 'fixed',
      '-ms-transform': 'translateZ(0)'
    },
    resetLeft: {
      left: 0
    },
    primaryTitle: {
      color: palette.primary.light,
      fontFamily: 'prompt',
      fontWeight: '400',
      fontSize: '.9em',
      margin: '12px',
      textTransform: 'uppercase',
      letterSpacing: '0.15em',
      display: 'inline'
    },
    locationName: {},
    home: {
      fontSize: '2.2em',
      paddingTop: 4
    },
    headerIcons: {
      fontSize: '2.2em',
      color: palette.primary.main,
      marginLeft: '42px',
      margin: '4px'
    },
    bell: {
      color: palette.primary.main,
      fontSize: '1.9em',
      margin: '5px 0 0 4px'
    },
    bellWithNotifications: {
      color: palette.secondary.main,
      fontSize: '1.9em',
      margin: '5px 0 0 4px'
    },
    titleContainer: {
      marginTop: -2,
      marginLeft: 4,
      [breakpoints.down('lg')]: {
        fontSize: '.9em'
      },
      display: 'flex',
      alignItems: 'center'
    },
    spacer: {
      width: 20,
      display: 'inline-block'
    },
    appBarCont: {
      paddingRight: '12px',
      marginLeft: `calc( ${variables.navigationWidth}px)`,
      width: `calc(100% - ${variables.navigationWidth}px - 58px)`,
      paddingLeft: 40,
    },
    navBadge: {
      '& > span': {
        height: '20px',
        padding: '2px 6px',
        marginRight: '-22px',
        fontSize: '.9em',
        position: 'absolute',
        top: '-10px',
        [variables.iEOnly]: {
          top: '-55px'
        }
      }
    },
    menuLink: {
      color: palette.grey[200],
      marginLeft: '6rem',
      [breakpoints.up('xl')]: {
        marginLeft: '12rem'
      }
    },
    header: {
      borderBottom: `1px solid ${palette.grey[300]}`,
      display: 'flex',
      flexFlow: 'row wrap',
      padding: '3.2rem 1.6rem'
    },
    caption: {
      ...typography.caption
    },
    linkButton: {
      fontSize: '1em',
      margin: 0,
      padding: 0,
      minWidth: 0,
      minHeight: 0,
      transition: 'none!important',
      backgroundColor: 'white!important'
    },
    round: {
      borderRadius: '50%',
      minHeight: 55,
      minWidth: 55,
      marginTop: -12
    },
    centered: {
      margin: `0 auto ${unit}px`,
      [breakpoints.up('xl')]: {
        maxWidth: '1600px'
      }
    },
    pushDown: {
      marginTop: 5
    },
    pushDownLarge: {
      marginTop: 70
    },
    pushDownMedium: {
      marginTop: 35
    },
    primaryMain: {
      color: palette.primary.main,
      fontWeight: 500
    },
    content: {
      flex: 1,
      padding: '3.2rem',
      fontFamily: 'Nunito, sans-serif',
      backgroundColor: 'white',
      margin: 50,
      border: `1px solid ${palette.grey[300]}`,
      borderRadius: 8
    },
    noPaddingTop: {
      paddingTop: 0
    },
    noPadding: {
      padding: 0,
    },
    noMarginLeft: {
      marginLeft: 0
    }
  });
