export default ({ palette }) => ({
  tabText: {
    color: palette.primary.light
  },
  selectedTabText: {
    '& span': {
      color: palette.secondary.main
    }
  },
  tabLabel: {
    fontSize: '1.7em',
    textTransform: 'none'
  },
  flexContainer: {
    height: 77,
    justifyContent: 'space-between'
  },
  tabsIndicator: {
    backgroundColor: palette.secondary.main,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: 5
  },
  popupTabs: {
    padding: '0 8%'
  }
});
