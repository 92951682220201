import React from 'react';
import { withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import classNames from 'classnames';

const Index = ({ icon, iconType, classes, description, actionComponent, large, white }) =>
  <div className={classNames(
    classes.root,
    { [classes.whiteBackground]: white }
  )}>
    <div>
      <div className={classes.centered}>
        <Badge
          badgeContent={<FontAwesomeIcon icon={['fas', 'times-circle']}/>}
          className={classes.badge}
        >
          <FontAwesomeIcon
            icon={[iconType || 'fas', icon]}
            className={classes.emptyState}
          />
        </Badge>
      </div>
      <Typography className={classes.description}>{description}</Typography>
      <div className={classNames(classes.centered, {
        [classes.largeActionButton]: large
      })}>
        {actionComponent}
      </div>
    </div>
  </div>;

export default withStyles(styles)(Index);
