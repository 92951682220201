import React from 'react';
import {
  withRouter
} from 'react-router-dom';
import Logo from '../Logo/index';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const styles = ({ palette, variables }) => ({
  logoContainer: {
    alignItems: 'center',
    backgroundColor: palette.primary.main,
    display: 'flex',
    height: variables.appBarHeight,
    borderBottom: `1px solid ${palette.primary.dark}`,
    padding: '15px',
    width: variables.navigationWidth,
    paddingTop: 25,
    position: 'absolute',
    borderRight: `1px solid ${palette.primary.dark}`,
    top: 0
  }
})

const TopNav =
  ({
     classes,
     beforeNavigate,
     history
   }) =>
    <div className={classes.logoContainer}>
      <Button
        to="/"
        onClick={() => beforeNavigate ? beforeNavigate(() => history.push('/')) : history.push('/')
        }
      >
        <Logo/>
      </Button>
    </div>

export default withStyles(styles)(withRouter(TopNav));
