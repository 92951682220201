import api from 'utils/api';
import { apiUrl } from 'app-constants';
import { ProtocolModel, GroupModel } from './models';
import { orderedMapFromArray } from 'utils/immutable';
import { normalizeProtocolForSaving, normalizeProtocol } from './normalize';

const getGroups = () =>
  api.get(`${apiUrl}/groups`)
    .then(result => {
      if (result.data && result.data.items) {
        return orderedMapFromArray(result.data.items, GroupModel);
      }
      throw new Error(result);
    });

const getGroupProtocols = groupId =>
  api.get(`${apiUrl}/group/${groupId}/protocols`)
    .then(result => {
      if (result.data && result.data.items) {
        return result.data.items
          .map(protocol =>
            new ProtocolModel(normalizeProtocol(protocol))
          )
      }
      throw new Error(result);
    });

const searchProtocols = (search, groups) =>
  api.post(`${apiUrl}/protocols`, { search })
    .then(result => {
      if (result.data && result.data.items) {
        return result.data.items
          .reduce(
            (Groups, protocol) =>
              Groups.updateIn([protocol.groupId, 'protocols'],
                  protocols => protocols.concat(
                new ProtocolModel(normalizeProtocol(protocol))
              ))
        ,groups.map(group => group.set('protocols', [])))
      }
      throw new Error(result);
    });


const saveProtocol = protocol =>
  protocol.id &&
  api.put(`${apiUrl}/protocol/${protocol.id}`, normalizeProtocolForSaving(protocol))
    .then(result => {
      if (result.data) {
        return result.data
      }
      throw new Error(result);
    }) ||
  api.post(`${apiUrl}/protocol`, normalizeProtocolForSaving(protocol))
    .then(result => {
      if (result.data) {
        return result.data
      }
      throw new Error(result);
    });

const deleteProtocol = id =>
  api.delete(`${apiUrl}/protocol/${id}`)
    .then(result => {
      if (result.data) {
        return result.data
      }
      throw new Error(result);
    });

export {
  getGroups,
  getGroupProtocols,
  saveProtocol,
  deleteProtocol,
  searchProtocols
}
