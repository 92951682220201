import {
  APP,
  RESET_STATE
} from 'constants/index';
import ImmutableState from './initialState';
import { getNowInEpoch } from 'utils/date';
import { Set } from 'immutable';

const initialState = new ImmutableState({});

const reducer = (state = initialState, action) =>

  action.type === APP.RESET_STATE
  && initialState ||

  action.type === RESET_STATE
  && initialState ||

  action.type === APP.SET_LOGIN_CONTEXT
  && state.merge({ loginContext: action.loginContext }) ||

  action.type === APP.SET_LOGIN_CONTEXT_FETCH_ID
  && state.set('loginContextFetchId', Math.random() ) ||

  action.type === APP.SET_AVAILABLE_LOCATIONS_DETAILS
  && state.merge({
    availableLocations: action.availableLocations,
    availableLocationsDetailsFetchId:
      action.userContext.accountId || action.userContext.locationId || Math.random(),
    availableLocationsDetailsFetchTime: getNowInEpoch()
  }) ||

  action.type === APP.CLEAR_AVAILABLE_LOCATIONS
  && state.merge({
    availableLocations: Set([]),
    availableLocationsDetailsFetchId: null
  }) ||

  action.type === APP.HIDE_SIDE_NAV
  && state.merge({ sideNavOpen: false }) ||

  action.type === APP.SHOW_SIDE_NAV
  && state.merge({ sideNavOpen: true }) ||


  action.type === APP.UPDATE_VALUE
  && state.merge({
    [action.key]: action.value
  }) ||

  action.type === APP.SET_VALUE
  && state.set(action.key, action.value) ||

  state;

export default reducer;

