const normalizePageForSaving = page => ({
  content: page.content,
  ...(page.id && { id: page.id } || {}),
  title: page.title,
  published: page.published
})

export {
  normalizePageForSaving
}
