import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { logOutUrl, passwordChange } from 'utils/auth';
import Icon from 'components/Icons/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { popSnackBar } from 'components/Snackbar/actions';

const styles = {
  resetFont: {
    fontSize: '.5em'
  },
  container: {
    position: 'relative',
    userSelect: 'none'
  },
  profileLink: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    '& > *:first-child': {
      marginRight: '0.8rem'
    }
  }
};

const changePassword = (email, closeMenu) => {
  passwordChange(email);
  closeMenu();
  popSnackBar('Please check your email for a reset password link')
};

const logout = () => {
  localStorage.removeItem('access-token');
  localStorage.removeItem('id-token');
  localStorage.removeItem('expires-at');
  localStorage.removeItem('impersonate-account');
  window.location.href = logOutUrl();
};

const Profile =
  ({
     profile,
     classes
   }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null);

    return (
      <React.Fragment>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => {
            () => {
              changePassword(
                profile.email
              );
              return handleClose();
            }
          }}>Change Password</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
        <Icon
          className={classes.profileLink}
          icon="user-circle"
          size="3x"
          onClick={handleClick}
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="white"
        />
      </React.Fragment>
    )
  }

export default withStyles(styles)(withRouter(Profile));
