import React from 'react';
import api from 'utils/api';
import { apiUrl } from 'app-constants';
import { UserModel } from './models';
import { normalizeUserForSaving } from './normalize';

const getUsers = () =>
  api.get(`${apiUrl}/users`)
    .then(result => {
      if (result.data) {
        return result.data.items.map(user => new UserModel(user))
      }
      throw new Error(result.message.message || 'Error saving user');
    });

const addUser = user =>
  api.post(`${apiUrl}/user`, normalizeUserForSaving(user))
    .then(result => {
      if (result.data) {
        return result.data;
      }
      throw new Error(result.message.message || 'Error saving user');
    });

const editUser = user =>
  api.put(`${apiUrl}/user/${user.id}`, normalizeUserForSaving(user))
    .then(result => {
      if (result.data) {
        return result.data;
      }
      throw new Error(result.message.message || 'Error saving user');
    });

const deleteUser = (user_id) =>
  api.delete(`${apiUrl}/user/${user_id}`)
    .then(result => {
      if (result) {
        return result.status === 200;
      }
      throw new Error(result.message || 'Error deleting user');
    });

const getUser = id =>
  api.get(`${apiUrl}/user/${id}`)
    .then(result => {
      if (result.data) {
        return new new UserModel(result.data)
      }
      throw new Error(result);
    });

export {
  getUser,
  addUser,
  editUser,
  deleteUser,
  getUsers
};
