import { connect } from 'react-redux';
import SnackBar from './View';

const mapStateToProps = ({ snackBar }) => ({
  message: snackBar.message,
  open: snackBar.open,
  variant: snackBar.variant
});

export default connect(mapStateToProps)(SnackBar);
