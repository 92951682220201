import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton/IconButton';
import classNames from 'classnames';
import Template from 'components/Template';
import { toggleAreYouSureModal } from 'modules/Modals/actions';
import Stepper from './Stepper';
import Icon from 'components/Icons/Icon';
import Paper from '@material-ui/core/Paper';
import styles from '../styles';
import InPageBlueNav from 'components/Section/InPageBlueNav';
import PrimaryActionButton from 'components/Button/PrimaryActionButton';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import Loading from 'components/Loading'

const Transition = props => <Slide direction="up" {...props} />;

const FullScreenDialog =
  ({
     classes,
     disabled,
     children,
     title,
     buttonText,
     buttonClass,
     open,
     toggleFn,
     subTitle,
     whiteBackground,
     checkOnNavigation,
     titleComponent,
     tabs,
     fullWidth,
     steps,
     underSteps,
     buttonVariant = "outlined",
     buttonDisabled,
     loading,
     rightDetail,
     noMarginLeft,
     ...rest
   }) => (
    <div>
      {buttonText &&
      (
        <PrimaryActionButton
          color="secondary"
          className={buttonClass}
          onClick={toggleFn}
          variant={buttonVariant}
          text={buttonText}
          disabled={buttonDisabled}
        />
      )}
      <Dialog
        className={classes.dialog}
        fullScreen
        open={open}
        onClose={() =>
          checkOnNavigation ?
            toggleAreYouSureModal(() => toggleFn()) : toggleFn()}
        TransitionComponent={Transition}
        PaperProps={whiteBackground && {
          style: {
            backgroundColor: 'white'
          }
        } || {}}
        {...rest}
      >
        <div>
          <Template
            rightDetail={rightDetail}
            showFullLogo
            title=""
            inModal
            noMarginLeft={noMarginLeft}
            beforeNavigate={
              checkOnNavigation ?
                callBack => toggleAreYouSureModal(() => {
                  toggleFn();
                  return callBack()
                }) : callBack => {
                  toggleFn();
                  callBack();
                }}
          >
            <div>
              <InPageBlueNav
                checkOnNavigation={checkOnNavigation}
                toggleFn={toggleFn}
                titleComponent={titleComponent}
                title={title}
                subHeading={subTitle}
                tabs={tabs}
                rightDetail={rightDetail}
              />
              {underSteps &&
              <Paper className={classes.underNeathSteps}>
                <Stepper
                  steps={underSteps}
                  underSteps={true}
                  close={<IconButton
                    className={classNames(classes.iconButton)}
                    disableRipple={false}
                    onClick={() => checkOnNavigation ? toggleAreYouSureModal(() => toggleFn()) : toggleFn()}
                  >
                    <Icon
                      color="lightGrey"
                      icon="chevron-left"
                      iconType="fal"
                      size={'1x'}
                    />
                  </IconButton>}
                />
              </Paper>
              }
              <div className={classNames(
                classes.content,
                {
                  [classes.fullWidth]: fullWidth,
                  [classes.underStepsContent]: underSteps
                }
              )}>
                {loading &&
                <React.Fragment>
                  <Backdrop classes={{ root: classes.backDrop }} open={true}/>
                  <Loading className={classes.loader} width={120} height={120}/>
                </React.Fragment>
                }
                {children}
              </div>
            </div>
          </Template>
        </div>
      </Dialog>
    </div>
  );

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FullScreenDialog);
