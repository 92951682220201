import React from 'react';
import { connect } from 'react-redux';
import Dashboard from './View'

const mapStateToProps =
  ({ users }) =>
    ({
      loading: users.loading,
      user: users.user,
      showCreateOrEdit: users.showCreateOrEdit
    });

export default connect(mapStateToProps)(Dashboard);
