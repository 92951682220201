import { orderedMapFromArray } from 'utils/immutable';
import { Step, Section, CallToAction } from './models';
import { sort } from 'utils/sorting';

const normalizeSectionForSaving = section => ({
  id: section.id,
  type: section.type,
  ...(section.content && { content: section.content } || {}),
  ...(section.duration && { duration: section.duration } || {}),
  ...(section.style && { style: section.style } || {})
});

const normalizeStepForSaving = step => ({
  id: step.id,
  title: step.title,
  ...(step.callsToAction && {
    callsToAction: sort(
      step.callsToAction.toIndexedSeq().toArray()
      , 'order')
  } || {}),
  sections: sort(
    step.sections
      .toIndexedSeq()
      .toArray(), 'order'
  ).map(normalizeSectionForSaving)
});

const normalizeProtocolForSaving = protocol => ({
  title: protocol.title,
  groupId: protocol.groupId,
  steps: protocol.steps
    .toIndexedSeq()
    .toArray()
    .map(normalizeStepForSaving),
  published: protocol.published
});

const normalizeStepForDragging = step => ({
  id: step.order || 0,
  uuid: step.id,
  title: step.title,
  callsToAction: step.callsToAction,
  sections: step.sections
});

const normalizeStepFromDragging = step => ({
  ...step,
  id: step.uuid,
  order: step.id
});

const addIndex = (obj, index) => ({
  ...obj,
  order: index
});

const normalizeStep = (step, index) =>
  ({
    ...step,
    order: index,
    sections: orderedMapFromArray(step.sections && step.sections.map(addIndex || []), Section),
    callsToAction: orderedMapFromArray(step.callsToAction && step.callsToAction.map(addIndex) || [], CallToAction)
  });

const normalizeProtocol = protocol => ({
  ...protocol,
  steps: orderedMapFromArray((protocol.steps || []).map(normalizeStep), Step)
});

export {
  normalizeProtocolForSaving,
  normalizeProtocol,
  normalizeStepForDragging,
  normalizeStepFromDragging
}
