import React from 'react';
import { connect } from 'react-redux';
import View from './View'
import { Step, CallToAction } from 'Models/Protocol/models';
import { Map } from 'immutable';

const getProtocolsById = groups =>
  groups && groups
    .reduce((acc, group) =>
      acc.merge(
        group.protocols.reduce(
          (protocols, protocol) =>
            protocols.set(protocol.id, protocol), Map({})
        )
      ), Map({})) || Map({});

const mapStateToProps =
  ({ protocols }) =>
    ({
      showCreateOrEditCallToAction: protocols.showCreateOrEditCallToAction,
      protocol: protocols.protocol,
      selectedStep: protocols.protocol.getIn(['steps', protocols.selectedStep], Step({})),
      callToAction: protocols.selectedCallToAction || CallToAction({}),
      groups: protocols.groups,
      protocolsById: getProtocolsById(protocols.groups)
    });

export default connect(mapStateToProps)(View);
