import React from 'react';
import { Redirect } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    window.Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return <Redirect to="/protocols"/>
    // }

    return this.props.children;
  }
}
export default ErrorBoundary;
