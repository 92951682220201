export default ({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 0,
    top: '150px',
    right: '0px',
    left: '0px',
    bottom: '300px'
  },
  emptyState: {
    fontSize: '5em',
    color: palette.grey[500],
    backgroundColor: 'transparent'
  },
  badge: {
    zIndex: 5,
    color: palette.grey[500],
    '& > span': {
      fontSize: '2.5em'
    },
    '& > span svg': {
      border: '3px solid white',
      borderRadius: '50%',
      margin: '0 0 -15px -15px',
      backgroundColor: 'white'
    }
  },
  description: {
    fontSize: '1.3em',
    display: 'block',
    padding: '35px 30px 10px',
    textAlign: 'center',
    color: palette.grey[500]
  },
  centered: {
    display: 'flex',
    justifyContent: 'center'
  },
  largeActionButton: {
    marginTop: '30px'
  },
  whiteBackground: {
    backgroundColor: 'white',
    border: `1px solid ${palette.grey[300]}`,
    top: '40px',
    right: '30px',
    left: '30px',
    bottom: '30px'
  }
});
