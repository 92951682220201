import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core';

const styles = ({ palette, variables, spacing: { unit} }) => ({
  nav: {
    backgroundColor: 'white',
    '& > div': {
      backgroundColor: '#fff',
      width: variables.navigationWidth,
      marginBottom: unit * 8,
      marginTop: variables.topClearance
    },
    '& a': {
      padding: `${unit * 4}px ${unit * 8}px`,
      marginTop: unit * 10
    }
  },
  paper: {
    zIndex: 1
  }
})

const Navigation = ({ showSideNav, hideSideNav, children, classes }) =>
  <Drawer
    variant="persistent"
    className={classes.nav}
    open={true}
    elevation={0}
    classes={{
      paper: classes.paper
    }}
  >
    {children}
  </Drawer>


export default withStyles(styles)(Navigation);
