import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles';

const Icon =
  ({
     classes,
     className,
     color,
     size,
     inButton,
     mediumSpacedIcon,
     icon,
     iconType = 'fas',
     noMargin,
     circleWhite,
     inButtonIcon,
     reset,
     ...rest
   }) => (
    <FontAwesomeIcon
      icon={[iconType, icon]}
      className={classNames(
        className,
        { [classes.noMargin]: noMargin },
        { [classes.inButton]: inButton },
        { [classes.primaryColor]: !color },
        { [classes.secondaryColor]: color === 'secondary' },
        { [classes.greyColor]: color === 'grey' },
        { [classes.darkGreyColor]: color === 'darkGrey' },
        { [classes.greyBackGroundColor]: color === 'greyBackground' },
        { [classes.white]: color === 'white' },
        { [classes.lightGreyColor]: color === 'lightGrey' },
        { [classes.lightPrimary]: color === 'lightPrimary' },
        { [classes.primary]: color === 'primary' },
        { [classes.success]: color === 'success' },
        { [classes.error]: color === 'error' },
        { [classes.warning]: color === 'warning' },
        { [classes.circleWhite]: circleWhite }
      )}
      size={size || '2x'}
      {...rest}
    />
  );

export default withStyles(styles)(Icon);
