const getName = user =>
  user && `${user.first_name && user.first_name}${user.last_name && ` ${user.last_name}` || ''}` || '';

const maybeOutputS = count => count === 1 ? '' : 's';

const maybeTruncateName = (name='', chars=40) =>
  name.length > chars && `${name.substring(0, chars)}...` || name;

export {
  getName,
  maybeTruncateName,
  maybeOutputS,
}
