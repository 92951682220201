import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import storage from 'redux-persist/lib/storage';
import AppState from 'modules/App/initialState';
import UserState from 'modules/Users/initialState';
import ProtocolState from 'modules/Protocols/initialState';
import PageState from 'modules/Pages/initialState';
import { UserModel } from './Models/User/models';
import { ProtocolModel, GroupModel, Step, Section, CallToAction } from './Models/Protocol/models';

import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  transforms: [
    immutableTransform({
      records: [
        AppState,
        UserState,
        ProtocolState,
        UserModel,
        PageState,
        ProtocolModel, GroupModel, Step, Section, CallToAction
      ]
    })
  ],
  storage,
  blacklist: [
    'router',
    'pages'
  ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  applyMiddleware(
    thunkMiddleware
  )
);
const persistor = persistStore(store);

export { store, persistor };
