const RESET_STATE = 'RESET_STATE';

const PROTOCOLS = {
  TOGGLE_SECTIONS: 'PROTOCOLS_TOGGLE_SECTIONS',
  SET_VALUE: 'PROTOCOLS_SET_VALUE',
  SET_STATE: 'PROTOCOLS_SET_STATE',
  RECIEVE_GROUPS: 'PROTOCOLS_RECIEVE_GROUPS',
  RECIEVE_GROUPS_PROTOCOLS: 'PROTOCOLS_RECIEVE_GROUPS_PROTOCOLS',
  TOGGLE_CREATE_OR_EDIT_PROTOCOL: 'PROTOCOLS_TOGGLE_CREATE_OR_EDIT_PROTOCOL',
  UPDATE_PROTOCOL: 'PROTOCOLS_UPDATE_PROTOCOL',
  SELECT_STEP: 'PROTOCOLS_SELECT_STEP',
  UPDATE_SELECTED_STEP: 'PROTOCOLS_UPDATE_SELECTED_STEP',
  UPDATE_SELECTED_STEP_SECTION: 'PROTOCOLS_UPDATE_SELECTED_STEP_SECTION',
  ADD_SECTION_TO_SELECTED_STEP: 'PROTOCOLS_ADD_SECTION_TO_SELECTED_STEP',
  REMOVE_SECTION_FROM_SELECTED_STEP: 'PROTOCOLS_REMOVE_SECTION_FROM_SELECTED_STEP',
  ADD_NEW_STEP: 'PROTOCOLS_ADD_NEW_STEP',
  REMOVE_STEP: 'PROTOCOLS_REMOVE_STEP',
  TOGGLE_CREATE_OR_EDIT_CALL_TO_ACTION: 'PROTOCOLS_TOGGLE_CREATE_OR_EDIT_CALL_TO_ACTION',
  SAVE_PROTOCOL: 'PROTOCOLS_SAVE_PROTOCOL',
  UPDATE_SELECTED_CALL_TO_ACTION: 'PROTOCOLS_UPDATE_SELECTED_CALL_TO_ACTION',
  SAVE_CALL_TO_ACTION: 'PROTOCOLS_SAVE_CALL_TO_ACTION',
  REMOVE_CALL_TO_ACTION_FROM_SELECTED_STEP: 'PROTOCOLS_REMOVE_CALL_TO_ACTION_FROM_SELECTED_STEP',
  TOGGLE_EDIT_ALERT: 'PROTOCOLS_TOGGLE_EDIT_ALERT',
  UPDATE_SELECTED_ALERT: 'PROTOCOLS_UPDATE_SELECTED_ALERT',
  SAVE_ALERT: 'PROTOCOLS_SAVE_ALERT',
  TOGGLE_EDIT_TIMER: 'PROTOCOLS_TOGGLE_EDIT_TIMER',
  SAVE_TIMER: 'PROTOCOLS_SAVE_TIMER',
  UPDATE_SELECTED_TIMER: 'PROTOCOLS_UPDATE_SELECTED_TIMER',
  REORDER_STEPS: 'PROTOCOLS_REORDER_STEPS',
  DROP_SECTION: 'PROTOCOLS_DROP_SECTION',
  DROP_CALL: 'PROTOCOLS_DROP_CALL'
};

const PAGES = {
  TOGGLE_CREATE_OR_EDIT: 'PAGES_TOGGLE_CREATE_OR_EDIT',
  UPDATE_VALUE: 'PAGES_UPDATE_VALUE',
  UPDATE_CURRENT_PAGE: 'PAGES_UPDATE_CURRENT_PAGE',
  RECEIVE_PAGES: 'PAGES_RECEIVE_PAGES'
};

const USERS = {
  RESET_STATE: 'USERS_RESET_STATE',
  EDIT: 'USERS_EDIT',
  SAVE: 'USERS_SAVE',
  LOADING: 'USERS_LOADING',
  UPDATE_VALUE: 'USERS_UPDATE_VALUE',
  RECEIVE_USERS: 'USERS_RECEIVE_USERS',
  SET_VALUE: 'USERS_SET_VALUE',
  SET_STATE: 'USERS_SET_STATE',
  UPDATE_USER: 'USERS_UPDATE_USER',
  TOGGLE_CREATE_OR_EDIT: 'USERS_TOGGLE_CREATE_OR_EDIT',
  SAVE_USER: 'USERS_SAVE_USER'
};

const SNACKBAR = {
  RESET_STATE: 'SNACKBAR_RESET_STATE',
  SHOW: 'SNACKBAR_SHOW',
  HIDE: 'SNACKBAR_HIDE',
  TOGGLE: 'SNACKBAR_TOGGLE'
};

const MODALS = {
  RESET_STATE: 'MODALS_RESET_STATE',
  TOGGLE_INSERT_CONTENT_LINK: 'MODALS_TOGGLE_INSERT_CONTENT_LINK',
  CLOSE_INSERT_CONTENT_LINK: 'MODALS_CLOSE_INSERT_CONTENT_LINK',
  CLOSE_ALL: 'MODALS_CLOSE_ALL',
  SET_VALUE: 'MODALS_SET_VALUE',
  TOGGLE_ARE_YOU_SURE_MODAL: 'MODALS_TOGGLE_ARE_YOU_SURE_MODAL',
  TOGGLE_SEND_ANNOUNCEMENT_MODAL: 'MODAL_TOGGLE_SEND_ANNOUNCEMENT_MODAL'
};

const APP = {
  RESET_STATE: 'APP_CONTEXT_RESET_STATE',
  SET_LOGIN_CONTEXT: 'APP_CONTEXT_SET_LOGIN_CONTEXT',
  SET_AVAILABLE_LOCATIONS: 'APP_CONTEXT_SET_AVAILABLE_LOCATIONS',
  SET_AVAILABLE_LOCATIONS_DETAILS: 'APP_SET_AVAILABLE_LOCATIONS_DETAILS',
  HIDE_SIDE_NAV: 'APP_CONTEXT_HIDE_SIDE_NAV',
  SHOW_SIDE_NAV: 'APP_CONTEXT_SHOW_SIDE_NAV',
  SELECT_ACCOUNT: 'APP_CONTEXT_SELECT_ACCOUNT',
  SET_HOSPITAL_DETAILS: 'APP_SET_HOSPITAL_DETAILS',
  SET_HOSPITAL_DETAILS_FETCH_ID: 'APP_SET_HOSPITAL_DETAILS_FETCH_ID',
  SET_LOADING_AVAILABLE_ROLES: 'APP_SET_LOADING_AVAILABLE_ROLES',
  SET_AVAILABLE_ROLES: 'APP_SET_AVAILABLE_ROLES',
  SET_VALUE: 'APP_SET_VALUE',
  SET_LOGIN_CONTEXT_FETCH_ID: 'APP_SET_LOGIN_CONTEXT_FETCH_ID',
  SET_LOADING_HOSPITAL_DETAILS: 'APP_SET_LOADING_HOSPITAL_DETAILS',
  CLEAR_AVAILABLE_LOCATIONS: 'APP_CLEAR_AVAILABLE_LOCATIONS',
  REMOVE_PENDING_REQUEST: 'APP_REMOVE_PENDING_REQUEST',
  UPDATE_VALUE: 'APP_UPDATE_VALUE',
  TOGGLE_PENDING_EDIT: 'APP_TOGGLE_PENDING_EDIT',
  UPDATE_PENDING_EDIT_DETAILS: 'APP_UPDATE_PENDING_EDIT_DETAILS',
  TOGGLE_QR_MODAL: 'APP_TOGGLE_QR_MODAL'
};

export {
  RESET_STATE,
  SNACKBAR,
  MODALS,
  APP,
  USERS,
  PROTOCOLS,
  PAGES
};
