import React from 'react';
import EmptyState from 'components/Section/EmptyState';
import Template from '../components/Template';

export default () =>
  <Template title="An error has occurred">
    <EmptyState
      description="It appears we are currently having system issues. Please try again later. "
      icon="heart-rate"
      large
      white
    />
  </Template>
