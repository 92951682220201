import React from 'react';
import Dialog from 'components/Dialog/FullScreen';
import { withStyles } from '@material-ui/core';
import {
  toggleCreateOrEdit,
  updateCurrentPage,
  savePage
} from '../actions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Editor from 'components/Editor';
import Drawer from '@material-ui/core/Drawer/Drawer';
import classNames from 'classnames';
import baseStyles from '../../styles';

const Published = ({ classes }) =>
  <Button
    variant="outlined"
    className={classes.published}
  >Page Published
  </Button>

const UnPublished = ({ classes }) =>
  <Button
    variant="outlined"
    className={classes.unPublished}
  >Page in Draft
  </Button>

const styles = ({ variables, palette }) => ({
  ...baseStyles({ palette, variables }),
  drawerPaper: {
    backgroundColor: 'white',
    top: variables.appBarHeight * 2,
    padding: 30,
    width: variables.extraProtocolWidth + variables.navigationWidth,
    paddingBottom: 310
  },
  titleField: {
    width: 400,
    '& *:before': {
      display: 'none'
    }
  },
  published: {
    color: palette.success,
    size: 350,
    border: `1px solid ${palette.success}`
  },
  unPublished: {
    color: palette.warning.main,
    size: 350,
    border: `1px solid ${palette.warning.main}`
  },
  container: {
    minHeight: 500,
    marginTop: 10,
    padding: 75
  },
  name: {
    width: 500
  }
});

const SaveDrawer = withStyles(styles)(
  ({ classes, pageEdited, page }) =>
    <Drawer
      variant="persistent"
      anchor="bottom"
      classes={{ paper: classes.drawer }}
      open={pageEdited || !page.published}
    >
      <div>
        {
          pageEdited &&
          <Button
            color="primary"
            onClick={() => savePage(page, false)}
          >
            Save as Draft
          </Button>
        }
        <Button
          variant="contained"
          color="primary"
          className={classNames(classes.saveButton)}
          onClick={() => savePage(page, true)}
          disabled={!page.content || !page.title}
        >Publish Page</Button>
      </div>
    </Drawer>
);

const CreateOrEdit =
  ({
     classes,
     showCreateOrEdit,
     page,
     pageEdited
   }) => page &&
    <Dialog
      open={showCreateOrEdit}
      title={page.id ? 'Edit Page' : 'Create Page'}
      whiteBackground
      toggleFn={() => toggleCreateOrEdit()}
      noMarginLeft
      rightDetail={
        page.published &&
        <Published classes={classes}/> || <UnPublished classes={classes}/>
      }
    >
      <div className={classes.container}>
        <TextField
          id="title"
          label="Page title"
          value={page.title}
          className={classes.name}
          onChange={e => updateCurrentPage('title', e.target.value)}
          margin="normal"
        />
        <Editor
          value={page.content}
          onChange={value => updateCurrentPage('content', value)}
          toolBarInline={false}
          height={300}
        />
      </div>
      <SaveDrawer
        page={page}
        pageEdited={pageEdited}
      />
    </Dialog> || null;

export default withStyles(styles)(CreateOrEdit);


