import React from 'react';
import MaterialTable from '@material-ui/core/Table';
import MaterialTableBody from '@material-ui/core/TableBody';
import MaterialTableHead from '@material-ui/core/TableHead';
import MaterialTableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const initialPadding = 12;

const styles = {
  minimize: {
    margin: '-7px 0 0 14px'
  },
  table: {
    fontFamily: 'Prompt, sans-serif',
    width: '100%'
  },
  tableHead: {
    textTransform: 'uppercase',
    '& tr td:first-child, tr th:first-child': {
      paddingLeft: initialPadding * 2
    },
    '& tr': {
      fontSize: '.8em'
    }
  },
  lessPadding: { padding: 12 },
  smallCell: {
    padding: '4px 24px',
    width: '30px'
  },
  tableBody: {
    '& tr:last-child td, tr:last-child th': {
      borderBottom: 'none'
    },
    '& tr td:first-child, tr th:first-child': {
      paddingLeft: initialPadding * 2
    }
  }
};

const NA = ({ className }) => (
  <div className={className}>
    <Icon>minimize</Icon>
  </div>
);

const TableComponent = ({ classes, className,  ...rest }) =>
  <MaterialTable className={classNames(classes.table, className)} {...rest}/>

const TableHeadComponent = ({ classes, className, ...rest }) =>
  <MaterialTableHead className={classNames(classes.tableHead, className)} {...rest}/>

const TableBodyComponent = ({ classes, className, ...rest }) =>
  <MaterialTableBody className={classNames(classes.tableBody, className)} {...rest}/>

const SmallCellComponent = ({ classes, className, ...rest }) =>
  <MaterialTableCell className={classNames(classes.smallCell, className)} {...rest}/>

const TableCellComponent = ({ classes, className, ...rest }) =>
  <MaterialTableCell className={classNames(classes.lessPadding, className)} {...rest}/>

const Table = withStyles(styles)(TableComponent);
const TableHead = withStyles(styles)(TableHeadComponent);
const TableBody = withStyles(styles)(TableBodyComponent);
const TableCell = withStyles(styles)(TableCellComponent);
const SmallCell = withStyles(styles)(SmallCellComponent);

export {
  Table,
  TableHead,
  TableBody,
  NA,
  SmallCell,
  TableCell
}
