import { Record } from 'immutable';
import { ProtocolModel } from 'Models/Protocol/models';

export default new Record({
  showSections: false,
  groups: null,
  loading: false,
  showCreateOrEdit: false,
  protocol: ProtocolModel({}),
  selectedStep: null,
  protocolEdited: false,
  showCreateOrEditCallToAction: false,
  selectedCallToAction: null,
  callToActionEdited: false,
  showEditAlert: false,
  selectedAlert: null,
  searchTerm: null,
  searchIndex: 0,
  protocolsFromSearch: null,
  selectedTimer: null,
  showEditTimer: false
}, 'ProtocolState')
