import { combineReducers } from 'redux';
import app from 'modules/App/reducers';
import snackBar from 'components/Snackbar/reducers';
import modals from 'modules/Modals/reducers';
import users from 'modules/Users/reducers';
import protocols from 'modules/Protocols/reducers';
import pages from 'modules/Pages/reducers';

export default combineReducers({
  app,
  snackBar,
  modals,
  users,
  protocols,
  pages
});
