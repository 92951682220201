import React, { Fragment, useImperativeHandle, useRef } from 'react';
import { OrderedMap } from 'immutable';
import { withStyles } from '@material-ui/core';
import parentStyles from './styles';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Icon from 'components/Icons/Icon';
import { dropCallToAction, removeCallToActionFromSelectedStep, toggleCreateOrEditCallToAction } from './actions';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { DragSource, DropTarget } from 'react-dnd';
import { hoverFunction } from './dropUtils';

const styles = ({ variables, palette }) => ({
  ...parentStyles({ variables, palette }),
  goToButton: {
    marginLeft: 15
  }
});

const variants = OrderedMap({
  'blueOutlined': {
    color: 'primary',
    variant: 'outlined',
    dashboardText: 'Blue Outlined (Postive)',
    id: 'blueOutlined'
  },
  'redOutlined': {
    color: 'secondary',
    variant: 'outlined',
    dashboardText: 'Red Outlined (Negative)',
    id: 'redOutlined'
  },
  'blueFilled': {
    color: 'primary',
    variant: 'contained',
    dashboardText: 'Blue Filled (Postive)',
    id: 'blueFilled'
  },
  'redFilled': {
    color: 'secondary',
    variant: 'contained',
    dashboardText: 'Red Filled (Negative)',
    id: 'redFilled'
  }
});

const RemoveCallToAction = withStyles(styles)(
  ({ id, classes }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return <Fragment>
      <IconButton
        onClick={handleClick}>
        <Icon icon="trash" size={'xs'} color="grey"/>
      </IconButton>
      <Menu
        id="deleteCallToActionMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classNames(classes.menuPaper, classes.deleteMenu)
        }}
      >
        <MenuItem
          className={classes.sectionMenu}
          onClick={() => {
            removeCallToActionFromSelectedStep(id);
            return handleClose()
          }}
        >Remove this Call To Action
        </MenuItem>
        )}
      </Menu>
    </Fragment>
  }
);

const SectionButton = withStyles(styles)(
  ({
     classes,
     children,
     ...rest
   }) =>
    <Button
      className={classNames(classes.sectionButton)}
      {...rest}
    >{children}
    </Button>
);

const GoToButton = withStyles(styles)(
  ({
     classes,
     children,
     ...rest
   }) =>
    <Button
      className={classes.goToButton}
      {...rest}
    >{children}
    </Button>
);

const Right = withStyles(styles)(
  ({ classes }) =>
    <Icon icon="caret-right" className={classNames(classes.iconSize, classes.marginRight10)}/>
);

const Cog = withStyles(styles)(
  ({ classes }) =>
    <Icon icon="cog" color="lightGrey" className={classNames(classes.iconSize, classes.marginLeft10)}/>
)

const Call =
  React.forwardRef(
    ({ call, showActions, disableClick, connectDragSource, connectDropTarget, isDragging }, ref) => {
      const elementRef = useRef(null);
      connectDragSource(elementRef);
      connectDropTarget(elementRef);
      useImperativeHandle(ref, () => ({
        getNode: () => elementRef.current
      }));
      return <div ref={elementRef}>
        <SectionButton
          onClick={() => !disableClick && toggleCreateOrEditCallToAction(call)}
          variant={variants.getIn([call.style, 'variant'], 'outlined')}
          color={variants.getIn([call.style, 'color'], 'primary')}
        >{call.title}
        </SectionButton>
        {showActions &&
        <Fragment>
          <GoToButton
            color={variants.getIn([call.style, 'color'], 'primary')}
            onClick={() => !disableClick && toggleCreateOrEditCallToAction(call)}
          >
            <Right/>
            {call.goToText}
            <Cog/>
          </GoToButton>
          <RemoveCallToAction id={call.id}/>
        </Fragment>
        }
      </div>
    });

const CallToActionButton = DropTarget(
  'CALL',
  {
    hover(props, monitor, component) {
      return hoverFunction(props, monitor, component, dropCallToAction)
    }
  },
  connect => ({
    connectDropTarget: connect.dropTarget()
  })
)(
  DragSource(
    'CALL',
    {
      beginDrag: props =>
        ({
          id: props.id,
          index: props.index
        })
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  )(Call)
);

export {
  CallToActionButton,
  variants
}
