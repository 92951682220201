export default {
  showSendAnnouncementModal: false,
  showInsertContentLink: false,
  showApproveUser: false,
  onInsertLink: () => {},
  onSendAnnouncement: () => {},
  announcementInfo: {},
  areYouSureString: null,
  areYouSureTitle: null,
  buttonText: null
}
