import { Record } from 'immutable';

export default new Record({
  loginContext: {},
  sideNavOpen: true,
  pendingUserRequests: null,
  availableLocationsDetailsFetchId: null,
  availableLocationsDetailsFetchTime: 0,
  availableLocations: [],
  hospitalInfo: null,
  hospitalInfoFetchId: null,
  hospitalInfoFetchTime: false,
  loadingAvailableRoles: true,
  availableRoles: null,
  loginContextFetchId: null,
  availableLocationsFetchId: null,
  availableRolesFetchId: null,
  actionItemsTab: 0,
  userCounts: {},
  showQrModal: false,
  accountMetaData: {},
  impersonatedAccount: null
}, 'AppState');
