import React from 'react';
import Popup from 'components/Dialog/Popup';
import TextInput from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';
import { saveUser, toggleCreateOrEdit, updateUser } from '../actions';
import Button from '@material-ui/core/Button';

const styles = ({ palette }) => ({
  container: {
    width: '100%',
    height: '100%',
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 100px'
  },
  saveButton: {
    width: '350px',
    margin: '0px auto 0px'
  }
});

const View =
  ({
     loading,
     classes,
     showCreateOrEdit,
     user
   }) => user &&
    <Popup
      title={ user.id && 'Edit User' || 'Create User'}
      toggleFn={() => toggleCreateOrEdit()}
      open={showCreateOrEdit}
      medium
      noPadding
    >
      <div className={classes.container}>
        <TextInput
          label="First Name"
          value={user.firstName}
          onChange={e =>
            updateUser('firstName', e.target.value)
          }
        />
        <TextInput
          label="Last Name"
          value={user.lastName}
          onChange={e =>
            updateUser('lastName', e.target.value)
          }
        />
        <TextInput
          disabled={Boolean(user.id)}
          label="Email"
          value={user.email}
          onChange={e =>
            updateUser('email', e.target.value)
          }
        />
      </div>
      <div className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={() => saveUser(user)}
        >Save User</Button>
      </div>
    </Popup> || null

export default withStyles(styles)(View);

