import React from 'react';
import Template from 'components/Template';
import EmptyState from '../components/Section/EmptyState';
import Page from '../components/Section/Page';

export default () => (
  <Template
    title="Page not found"
  >
    <Page>
      <EmptyState
        description="Looks like we took a wrong turn somewhere. Page not found!"
        icon="car-crash"
        large
        white
      />
    </Page>
  </Template>
);
