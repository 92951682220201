
export const STATUSES = {
  REQUESTED: {
    name: 'Requested',
    id: 1,
    userCountField: 'count_requested'
  },
  INVITED: {
    name: 'Invited',
    id: 2,
    userCountField: 'count_invited'
  },
  APPROVED: {
    name: 'Approved',
    id: 3,
    userCountField: 'count_approved'
  },
  TEMPORARY: {
    name: 'Temporary',
    id: 4,
    userCountField: 'count_temporary'
  },
  REVOKED: {
    name: 'Revoked',
    id: 5,
    userCountField: 'count_revoked'
  },
  EXPIRED: {
    name: 'Expired',
    id: 999,
    userCountField: 'count_expired'
  },
  ROLE_REQUEST: {
    name: 'Role Request',
    id: 888,
    userCountField: 'roleRequestCount'
  }
};

export const STATUSES_ORDERED = [
  STATUSES.APPROVED,
  STATUSES.INVITED,
  STATUSES.TEMPORARY,
  STATUSES.REQUESTED,
  STATUSES.REVOKED,
  STATUSES.EXPIRED
];

export const DOCUMENT_STATUSES = {
  VIEWED: {
    id: 'viewed',
    name: 'Viewed'
  },
  SIGNED: {
    id: 'agreed',
    name: 'Signed'
  },
  NOT_SIGNED: {
    id: 'not_signed',
    name: 'Not Signed'
  }
};

export const DOCUMENT_STATUSES_ORDERED = [
  DOCUMENT_STATUSES.VIEWED,
  DOCUMENT_STATUSES.SIGNED,
  DOCUMENT_STATUSES.NOT_SIGNED
];

export const apiUrl = process.env.REACT_APP_API_URL;
export const apiURlV2 = process.env.REACT_APP_API_URL;

export const STATUSES_ELIGIBLE = [
  STATUSES.APPROVED,
  STATUSES.INVITED,
  STATUSES.TEMPORARY
];

export const allowedUserTypes = ['LocationManager', 'AccountManager'];


