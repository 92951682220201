import {
  MODALS
} from 'constants/index';
import initialState from './initialState';

const reducer = (state = initialState, action) =>

  (action.type === MODALS.TOGGLE_SEND_ANNOUNCEMENT_MODAL && typeof action.onSendAnnouncement === 'function')
  && {
    ...state,
    showSendAnnouncementModal: !state.showSendAnnouncementModal,
    onSendAnnouncement: action.onSendAnnouncement,
    announcementInfo: action.announcementInfo
  } ||

  action.type === MODALS.TOGGLE_SEND_ANNOUNCEMENT_MODAL
  && {
  ...state,
  showSendAnnouncementModal: !state.showSendAnnouncementModal
  } ||

  action.type === MODALS.TOGGLE_INSERT_CONTENT_LINK
  && {
    ...state,
    showInsertContentLink: !state.showInsertContentLink,
    onInsertLink: action.onInsertLink,
    onInsertContent: action.onInsertContent
  } ||

  action.type === MODALS.TOGGLE_ARE_YOU_SURE_MODAL
  && {
    ...state,
    showAreYouSureModal: !state.showAreYouSureModal,
    areYouSureCallBack: action.callBack,
    areYouSureString: action.areYouSureString || null,
    areYouSureTitle: action.areYouSureTitle || null,
    buttonText: action.buttonText || null,
  } ||

  action.type === MODALS.CLOSE_INSERT_CONTENT_LINK
  && {
    ...state,
    showInsertContentLink: false
  } ||

  action.type === MODALS.SET_VALUE
  && {
    ...state,
    [action.obj.key]: action.obj.value
  } ||

  state;

export default reducer;
