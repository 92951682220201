import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import Icon from 'components/Icons/Icon';
import classNames from 'classnames';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { removeStep } from './actions';
import styles from './styles';

const RemoveStep = withStyles(styles)(
  ({ id, classes, selected }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return <div>
      <div
        onClick={handleClick}
      >
        <Icon className={classes.removeIcon} size="lg" color={selected && 'white' || 'darkGrey'} icon="times-circle"/>
      </div>
      <Menu
        id="reallyDeleteStepMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classNames(classes.menuPaper, classes.deleteMenu)
        }}
      >
        <MenuItem
          className={classes.sectionMenu}
          onClick={e => {
            e.stopPropagation();
            removeStep(id);
            return handleClose()
          }}
        >Remove this Step
        </MenuItem>
        )}
      </Menu>
    </div>
  }
);

const AddNew = withStyles(styles)(
  ({ type, onClick, classes, color, smaller }) =>
    <Button
      onClick={onClick}
      className={classNames(classes.addNew, { [classes.smaller]: smaller})}
      color={color}
    >
      <Icon
        icon={"plus-circle"}
        size={"lg"}
        inButton
      />
      Add {type}</Button>
);

export {
  AddNew,
  RemoveStep
}

