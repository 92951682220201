import styles from 'components/Tab/styles';

export default ({ palette, variables, breakpoints }) => ({
  ...styles({ palette }),
  closeIcon: {
    fontSize: '3em',
    color: palette.grey[400]
  },
  content: {
    display: 'block',
    maxWidth: '90%',
    [breakpoints.up('xl')]: {
      maxWidth: variables.maxWidth
    },
    margin: `${variables.appBarHeight} auto 0`,
    width: '100%'
  },
  fullWidth: {
    width: '100%',
    maxWidth: '100%'
  },
  underNeathSteps: {
    height: 88,
    backgroundColor: 'white',
    marginTop: 166,
    marginBottom: -119,
    borderBottom: `1px solid ${palette.grey[300]}`,
    position: 'fixed',
    width: '100%',
    zIndex: 2,
    top: 0
  },
  underStepsContent: {
    marginTop: 170
  },
  dialogContent: {
    padding: '0 7em'
  },
  largeDialogContent: {
    padding: '0'
  },
  xLargeDialogContent: {
    padding: 10
  },
  largeDialog: {
    maxWidth: '960px'
  },
  mediumDialogContent: {
    padding: '0 2em'
  },
  mediumDialog: {
    maxWidth: '700px',
    backgroundColor: 'white'
  },
  noPadding: {
    padding: 0,
    overflowY: 'auto'
  },
  paperWidthMd: {
    padding: '0 4em'
  },
  centered: {
    textAlign: 'center'
  },
  backDrop: {
    zIndex: 10000
  },
  loader: {
    zIndex: 10001,
    position: 'absolute',
    left: '50%',
    width: '80px!important',
    height: '80px!important',
    marginLeft: -40,
    top: 300,
    color: 'white'
  },
  noMarginLeft: {
    marginLeft: 0
  }
});
