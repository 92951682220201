import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

const styles = ({
  progress: {
    display: 'block',
    margin: '0 auto',
    userSelect: 'none',
    zIndex: '-99999'
  }
});

const Loading = ({ classes, className, outerClasses, color = 'secondary' }) => (
  <div className={classNames(outerClasses)}>
    <CircularProgress
      className={classNames(className, classes.progress)}
      color={color}
    />
  </div>
);

Loading.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Loading);
