import React from 'react';
import Popup from 'components/Dialog/Popup';
import TextInput from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';
import { updateSelectedCallToAction, saveCallToAction, toggleCreateOrEditCallToAction } from '../actions';
import Button from '@material-ui/core/Button';
import { variants, CallToActionButton } from '../CallToAction';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import { CallToAction } from '../../../Models/Protocol/models';
import { getIn } from 'immutable';

const styles = ({ palette }) => ({
  container: {
    width: '100%',
    height: '100%',
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 100px'
  },
  saveButton: {
    width: '350px',
    margin: '0px auto 0px'
  },
  formControl: {
    marginTop: 30
  },
  preview: {
    backgroundColor: palette.grey[100],
    borderTop: `1px solid ${palette.grey[300]}`,
    borderBottom: `1px solid ${palette.grey[300]}`,
    margin: '20px 0 0',
    padding: '30px 100px',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '.9em',
    fontFamily: 'Prompt',
    color: palette.grey[600],
    letterSpacing: '0.1em'
  },
  groupName: {
    textTransform: 'uppercase',
    fontSize: '.9em',
    fontWeight: 400,
    fontFamily: 'Prompt',
    padding: 10,
    marginTop: 10,
    opacity: 1
  },
  teal: {
    borderBottom: `2px solid ${palette.teal.main}`
  },
  tealItem: {
    backgroundColor: palette.teal.transparent
  },
  cyan: {
    borderBottom: `2px solid ${palette.cyan.main}`
  },
  cyanItem: {
    backgroundColor: palette.cyan.transparent
  },
  blue: {
    borderBottom: `2px solid ${palette.blue.main}`
  },
  blueItem: {
    backgroundColor: palette.blue.transparent
  }
});

const View =
  ({
     loading,
     classes,
     selectedStep,
     showCreateOrEditCallToAction,
     callToAction,
     groups,
     protocolsById
   }) => groups &&
    <Popup
      title="Add Call To Action"
      toggleFn={() => toggleCreateOrEditCallToAction()}
      open={showCreateOrEditCallToAction}
      medium
      loading={loading}
      noPadding
    >
      <div className={classes.container}>
        <TextInput
          label="Call To Action Title"
          value={callToAction.title}
          onChange={e =>
            updateSelectedCallToAction({ title: e.target.value })
          }
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="buttonStyle">Button Style</InputLabel>
          <Select
            value={callToAction.style}
            onChange={e =>
              updateSelectedCallToAction({ style: e.target.value })
            }
            inputProps={{
              name: 'buttonStyle',
              id: 'buttonStyle'
            }}
          >{
            variants
              .toIndexedSeq()
              .toArray()
              .map(
                style =>
                  <MenuItem
                    key={style.id}
                    value={style.id}>{style.dashboardText}
                  </MenuItem>
              )
          }
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="buttonDestination">Button Destination</InputLabel>
          <Select
            renderValue={() => callToAction.goToText}
            value={callToAction.goToText}
            onChange={e =>
              updateSelectedCallToAction(e.target.value)
            }
            inputProps={{
              name: 'buttonDestination',
              id: 'buttonDestination'
            }}
          >
            <MenuItem
              key={'nextStep'}
              value={{ goTo: 'nextStep', goToText: 'Next Step' }}
              selected
            >
              Next Step
            </MenuItem>
            {groups &&
            groups
              .toIndexedSeq()
              .toArray()
              .map(
                group =>
                  ([
                    <MenuItem
                      disabled
                      className={classNames(classes.groupName, classes[group.color])}
                      key={group.title}
                    >{group.title}</MenuItem>,
                    ...((group.protocols || [])
                      .map(
                        protocol =>
                          <MenuItem
                            key={protocol.id}
                            value={{
                              goTo: protocol.id,
                              goToText: `${group.title} - ${protocol.title}`
                            }}
                            className={classes[`${group.color}Item`]}
                          >{protocol.title}
                          </MenuItem>
                      ))
                  ])
              )}
            )
            }
          </Select>
        </FormControl>
        {
          callToAction.goTo != 'nextStep' && protocolsById.get(callToAction.goTo) &&
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="buttonDestination">Individual Step</InputLabel>
            <Select
              renderValue={() => {
                const selectedProtocol = protocolsById.get(callToAction.goTo) || new CallToAction({});
                return getIn(selectedProtocol, ['steps', callToAction.goToStep, 'title'], '');
              }}
              value={callToAction.goToStep || ''}
              onChange={e => updateSelectedCallToAction({ goToStep: e.target.value })}
              inputProps={{
                name: 'buttonDestination',
                id: 'buttonDestination'
              }}
            >
              {(protocolsById.get(callToAction.goTo) || new CallToAction({}))
                .steps
                .toIndexedSeq()
                .toArray()
                .map(
                  step =>
                    ([
                      <MenuItem
                        className={classes.groupName}
                        value={step.id}
                        key={step.id}
                      >{step.title}
                      </MenuItem>
                    ])
                )}
              )
              }
            </Select>
          </FormControl>
        }
      </div>
      <div className={classes.preview}>
        Preview
        <CallToActionButton disableClick call={callToAction}/>
      </div>
      <div className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={() => saveCallToAction(callToAction)}
        >Save Call To Action</Button>
      </div>
    </Popup>

export default withStyles(styles)(View);

