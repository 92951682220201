import createAxios from 'axios';
import startsWith from 'lodash/fp/startsWith'
import { store } from 'persistentStore';
import { getIn } from 'immutable';
import { getNowInEpoch } from './date';
import { login } from './auth';

const initializeAxios = ({ absolute }) => {
  const state = store.getState();
  const accountId = getIn(state, ['app', 'loginContext', 'profile', 'account_id'], null);
  const superAdmin = getIn(state, ['app', 'loginContext', 'profile', 'super_admin'], null);
  const account = superAdmin ? { account: accountId } : {};

  const expiresAt = localStorage.getItem('expires-at');
  const timeTilExpiry = (expiresAt / 1000 - getNowInEpoch());
  if (timeTilExpiry < 2500) {
    login();
  }

  const axios = createAxios.create({
    headers: {
      common: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Pragma': 'no-cache'
      },
      Authorization: `Bearer ${localStorage.getItem('access-token')}`,
      ...account
    }
  });

  if (!absolute) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }

  return axios;
}

export default {
  api: () => initializeAxios({ absolute: false }),
  apiAbsolute: () => initializeAxios({ absolute: true }),

  async get(url, params) {
    const response = startsWith(url, 'http')
      ? await this.apiAbsolute().get(url, params)
      : await this.api().get(url, params);

    return response;
  },

  async post(url, params, headers) {
    try {
      const response = await this.api().post(url, params, { headers: headers || {} })
      return response;
    }
    catch (err) {
      return { data: null, error: err, message: err.response && err.response.data };
    }
  },

  async put(url, params) {
    const response = await this.api().put(url, params);
    return response;
  },

  async patch(url, params) {
    const response = await this.api().patch(url, params);
    return response;
  },

  async delete(url, params) {
    const response = await this.api().delete(url, params);
    return response;
  }

};
