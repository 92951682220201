import React, { useImperativeHandle, useRef } from 'react';
import { DrawerComponent as callForHelp } from './EmergencyCallInformCart';
import { DrawerComponent as richText } from './TextSection';
import { DrawerComponent as alert } from './Alert';
import { DrawerComponent as timer } from './Timer';
import { withStyles } from '@material-ui/core';
import { RemoveSection } from '../CreateOrEdit/components';
import { DragSource, DropTarget } from 'react-dnd';
import { dropSection } from '../actions';
import { hoverFunction } from '../dropUtils';

const styles = {
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
  }
};

const Fallback = () => null;

const types = {
  callForHelp,
  richText,
  alert,
  timer,
  Fallback
};

const Container =
  withStyles(styles)(
    ({
       classes,
       elementRef,
       opacity,
       children
     }) =>
      <div ref={elementRef} className={classes.sectionContainer}>
        {children}
      </div>
  );

const Section =
  React.forwardRef(
    ({
       section,
       isDragging,
       connectDragSource,
       connectDropTarget
     }, ref) => {
      if (!section) {
        return null;
      }
      const Component = types[section.get('type', 'Fallback')] || Fallback;
      const elementRef = useRef(null);
      connectDragSource(elementRef);
      connectDropTarget(elementRef);
      const opacity = isDragging ? 0 : 1;
      useImperativeHandle(ref, () => ({
        getNode: () => elementRef.current
      }));
      return <Container elementRef={elementRef} opacity={opacity}>
        <Component content={section.content} id={section.id} style={section.style} duration={section.duration} order={section.order}/>
        <RemoveSection id={section.id}/>
      </Container>
    }
  );

export default DropTarget(
  'SECTION',
  {
    hover(props, monitor, component) {
      return hoverFunction(props, monitor, component, dropSection)
    }
  },
  connect => ({
    connectDropTarget: connect.dropTarget()
  })
)(
  DragSource(
    'SECTION',
    {
      beginDrag: props =>
        ({
          id: props.id,
          index: props.index
        })
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  )(Section)
);
