import React from 'react';
import { connect } from 'react-redux';
import Pages from './View'

const mapStateToProps =
  ({ pages }) => ({
    showCreateOrEdit: pages.showCreateOrEdit,
    pages: pages.pages
  });

export default connect(mapStateToProps)(Pages);
