import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAlarmClock
} from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronLeft as falChevronLeft,
  faAngleDoubleLeft,
  faChevronCircleDown,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faBroadcastTower,
  faUsersMedical,
  faHeartbeat,
  faHome,
  faUserCircle,
  faCarCrash,
  faTimesCircle,
  faChevronLeft,
  faCaretRight,
  faPlusCircle,
  faAngleUp,
  faAngleDown,
  faCheckCircle,
  faCog,
  faTrash,
  faMinusCircle,
  faCircle,
  faSearch,
  faPencil
} from '@fortawesome/pro-solid-svg-icons';

export default {
  init: () =>
    library.add([
      faBroadcastTower,
      faUsersMedical,
      faHeartbeat,
      faHome,
      faUserCircle,
      faCarCrash,
      faTimesCircle,
      faChevronLeft,
      falChevronLeft,
      faAngleDoubleLeft,
      faChevronCircleDown,
      faChevronRight,
      faCaretRight,
      faPlusCircle,
      faAngleUp,
      faAngleDown,
      faCheckCircle,
      faCog,
      faTrash,
      faMinusCircle,
      faCircle,
      faSearch,
      faAlarmClock,
      faPencil
    ])
};
