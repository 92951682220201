import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';

const Content = withStyles(styles)(
  ({ children, classes, noPadding, centered, pushDown, pushDownLarge, pushDownMedium, noPaddingTop, ...rest }) =>
    <div className={
      classNames(
        classes.content,
        {
          [classes.noPadding]: noPadding,
          [classes.centered]: centered,
          [classes.pushDown]: pushDown,
          [classes.pushDownLarge]: pushDownLarge,
          [classes.pushDownMedium]: pushDownMedium,
          [classes.noPaddingTop]: noPaddingTop
        }
      )
    } {...rest}>{children}</div>
);

export {
  Content
}
