import { Record } from 'immutable';

const PageModel = new Record({
  id: null,
  title: '',
  createdAt: 0,
  updatedAt: 0,
  createdBy: '',
  updatedBy: '',
  published: false,
  content: ''
});

export {
  PageModel
}
