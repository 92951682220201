import {
  MODALS
} from 'constants/index';
import { store } from 'persistentStore';

const { dispatch } = store;

const toggleAreYouSureModal = (callBack, areYouSureString, areYouSureTitle, buttonText) =>
  dispatch({
    type: MODALS.TOGGLE_ARE_YOU_SURE_MODAL,
    callBack,
    areYouSureString,
    areYouSureTitle,
    buttonText
  });
export {
  toggleAreYouSureModal
};
