import React, { Fragment } from 'react';
import SnackBar from 'components/Snackbar';
import AreYouSureModal from '../../Modals/AreYouSure';
import CreateOrEditProtocol from 'modules/Protocols/CreateOrEdit';
import CreateOrEditCallToAction from 'modules/Protocols/CreateOrEditCallToAction';
import EditAlert from 'modules/Protocols/EditAlert';
import EditTimer from 'modules/Protocols/EditTimer';
import CreateOrEdit from 'modules/Users/CreateOrEdit';

export default () => (
  <Fragment>
    <SnackBar/>
    <AreYouSureModal/>
    <SnackBar/>
    <AreYouSureModal/>
    <CreateOrEditProtocol/>
    <CreateOrEditCallToAction/>
    <EditAlert/>
    <EditTimer/>
    <CreateOrEdit/>
  </Fragment>
);
