import { PAGES } from '../../constants';
import { store } from 'persistentStore';
import { getPages, addPage, editPage, deletePage as deletePageAction } from 'Models/Page'
import { popSnackBar } from '../../components/Snackbar/actions';
import errorHandler from 'utils/errorHandler';

const { dispatch } = store;

const toggleCreateOrEdit = page =>
  dispatch({
    type: PAGES.TOGGLE_CREATE_OR_EDIT,
    page
  })

const fetchPages = () =>
  getPages()
    .then(pages =>
      dispatch({
        type: PAGES.RECEIVE_PAGES,
        pages
      }))
    .catch(pages => {
      errorHandler(pages);
      popSnackBar('There was a problem fetching pages', 'error');
    })

const deletePage = id => {
  updateValue('loading', true);
  deletePageAction(id)
    .then(() => {
      fetchPages();
      popSnackBar('Page successful deleted');
    })
    .catch(error => {
      fetchPages();
      updateValue('loading', false);
      popSnackBar('There was a problem deleting the Page', 'error');
      errorHandler(error);
    });
}

const updateValue = (key, value) =>
  dispatch({
    type: PAGES.UPDATE_VALUE,
    key,
    value
  });

const savePage = (page, published = false) => {
  updateValue('loading', true);
  const pageToSave = { ...(page.toObject()), published };
  return (page.id ? editPage(pageToSave) : addPage(pageToSave))
    .then(() => {
      fetchPages();
      toggleCreateOrEdit();
      popSnackBar('Page successful saved');
    })
    .catch(error => {
      fetchPages();
      updateValue('loading', false);
      popSnackBar('There was a problem saving the Page', 'error');
      errorHandler(error);
    });
}

const updateCurrentPage = (key, value) =>
  dispatch({
    type: PAGES.UPDATE_CURRENT_PAGE,
    key,
    value
  })

export {
  toggleCreateOrEdit,
  fetchPages,
  deletePage,
  updateValue,
  updateCurrentPage,
  savePage
}
