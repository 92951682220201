import {
  PROTOCOLS,
  RESET_STATE
} from 'constants/index';
import ProtocolState from './initialState';
import { CallToAction } from 'Models/Protocol/models';
import { Section, Step } from 'Models/Protocol/models';
import uuid from 'uuid/v4';
import { orderedMapFromArray } from 'utils/immutable';
import { OrderedMap, get } from 'immutable';
import { normalizeStepFromDragging } from '../../Models/Protocol/normalize';

const addNewSection = (sections, type, emptySection) => {
  const unique = uuid();
  return sections.set(unique, Section({ id: unique, type, ...emptySection }))
};

const sortIndex = (index, dragIndex, dropIndex) => {
  if (index === dragIndex) {
    return dropIndex;
  }
  const direction = dragIndex > dropIndex && 'down' || 'up';
  if (direction === 'down' && index >= dropIndex && index <= dragIndex) {
    return index + 1;
  }
  if (direction === 'up' && index <= dropIndex && index >= dragIndex) {
    return index - 1;
  }
  return index;
};

const initialState = new ProtocolState({});

const reducer = (state = initialState, action) =>

  action.type === PROTOCOLS.SET_STATE
  && state.merge(action.state) ||

  action.type === PROTOCOLS.SET_VALUE
  && state.set(action.key, action.value) ||

  action.type === PROTOCOLS.TOGGLE_SECTIONS
  && state.set('showSections', !state.showSections) ||

  action.type === PROTOCOLS.RECIEVE_GROUPS
  && state.merge({
    loading: false,
    groups: action.groups
  }) ||

  action.type === PROTOCOLS.RECIEVE_GROUPS_PROTOCOLS
  && state.setIn(['groups', action.groupId, 'protocols'], action.protocols) ||

  action.type === PROTOCOLS.TOGGLE_CREATE_OR_EDIT_PROTOCOL
  && state.merge({
    showCreateOrEdit: !state.showCreateOrEdit,
    loading: false,
    protocol: action.protocol,
    protocolEdited: false,
    selectedStep: get(action.protocol, 'steps', OrderedMap({})).first().id
  }) ||

  action.type === PROTOCOLS.UPDATE_PROTOCOL
  && state.setIn(['protocol', action.key], action.value).set('protocolEdited', true) ||

  action.type === PROTOCOLS.SELECT_STEP
  && state.set('selectedStep', action.step) ||

  action.type === PROTOCOLS.REMOVE_STEP
  && state
    .deleteIn(['protocol', 'steps', action.id])
    .merge({
      selectedStep: state.getIn(['protocol', 'steps'], OrderedMap({})).first().id,
      protocolEdited: true
    }) ||

  action.type === PROTOCOLS.UPDATE_SELECTED_CALL_TO_ACTION
  && state
    .merge({
      selectedCallToAction: state.selectedCallToAction.merge(action.callToAction),
      callToActionEdited: true
    }) ||

  action.type === PROTOCOLS.DROP_SECTION
  && state
    .updateIn(['protocol', 'steps', state.selectedStep, 'sections'],
      sections =>
        sections.map(
          section =>
            section.set('order', sortIndex(section.order, action.dragIndex, action.dropIndex))
        ))
    .set('protocolEdited', true) ||

  action.type === PROTOCOLS.DROP_CALL
  && state
    .updateIn(['protocol', 'steps', state.selectedStep, 'callsToAction'],
      calls =>
        calls.map(
          call =>
            call.set('order', sortIndex(call.order, action.dragIndex, action.dropIndex))
        ))
    .set('protocolEdited', true) ||

  action.type === PROTOCOLS.UPDATE_SELECTED_ALERT
  && state.set('selectedAlert', state.selectedAlert.merge(action.alert)) ||

  action.type === PROTOCOLS.UPDATE_SELECTED_TIMER
  && state.set('selectedTimer', state.selectedTimer.merge(action.timer)) ||

  action.type === PROTOCOLS.UPDATE_SELECTED_STEP
  && state
    .setIn(['protocol', 'steps', state.selectedStep, action.key], action.value)
    .set('protocolEdited', true) ||

  action.type === PROTOCOLS.SAVE_CALL_TO_ACTION
  && state
    .updateIn(['protocol', 'steps', state.selectedStep, 'callsToAction'],
      callsToAction => callsToAction.set(action.callToAction.id, action.callToAction))
    .merge({
      selectedCallToAction: null,
      showCreateOrEditCallToAction: false,
      protocolEdited: true
    }) ||

  action.type === PROTOCOLS.SAVE_ALERT
  && state
    .setIn(['protocol', 'steps', state.selectedStep, 'sections', action.alert.id], action.alert)
    .merge({
      selectedAlert: null,
      showEditAlert: false,
      protocolEdited: true
    }) ||

  action.type === PROTOCOLS.SAVE_TIMER
  && state
    .setIn(['protocol', 'steps', state.selectedStep, 'sections', action.timer.id], action.timer)
    .merge({
      selectedTimer: null,
      showEditTimer: false,
      protocolEdited: true
    }) ||

  action.type === PROTOCOLS.TOGGLE_CREATE_OR_EDIT_CALL_TO_ACTION
  && state.merge({
    showCreateOrEditCallToAction: !state.showCreateOrEditCallToAction,
    selectedCallToAction: action.call || new CallToAction({ id: uuid(), order: action.callCount })
  }) ||

  action.type === PROTOCOLS.UPDATE_SELECTED_STEP_SECTION
  && state
    .setIn(['protocol', 'steps', state.selectedStep, 'sections', action.id, action.key], action.value)
    .set('protocolEdited', true) ||

  action.type === PROTOCOLS.ADD_SECTION_TO_SELECTED_STEP
  && state
    .updateIn(['protocol', 'steps', state.selectedStep, 'sections'],
      orderedMapFromArray([], Section),
      sections => addNewSection(sections, action.sectionType, action.emptySection))
    .set('protocolEdited', true)
  ||

  action.type === PROTOCOLS.ADD_NEW_STEP
  && state
    .updateIn(['protocol', 'steps'], OrderedMap({}),
      steps => steps.set(action.step.id, action.step)
    )
    .merge({
      selectedStep: action.step.id,
      protocolEdited: true
    })
  ||

  action.type === PROTOCOLS.REORDER_STEPS
  && state
    .setIn(['protocol', 'steps'],
      orderedMapFromArray(action.steps.map(normalizeStepFromDragging), Step)
    )
    .set('protocolEdited', true) ||

  action.type === PROTOCOLS.TOGGLE_EDIT_ALERT
  && state.merge({
    selectedAlert: action.alert,
    showEditAlert: !state.showEditAlert
  }) ||

  action.type === PROTOCOLS.TOGGLE_EDIT_TIMER
  && state.merge({
    selectedTimer: action.timer,
    showEditTimer: !state.showEditTimer
  }) ||

  action.type === PROTOCOLS.REMOVE_SECTION_FROM_SELECTED_STEP &&
  state
    .deleteIn(['protocol', 'steps', state.selectedStep, 'sections', action.id])
    .set('protocolEdited', true)
  ||

  action.type === PROTOCOLS.REMOVE_CALL_TO_ACTION_FROM_SELECTED_STEP &&
  state
    .deleteIn(['protocol', 'steps', state.selectedStep, 'callsToAction', action.id])
    .set('protocolEdited', true)
  ||

  action.type === PROTOCOLS.SAVE_PROTOCOL &&
  state.merge({
    loading: false,
    protocolEdited: false,
    protocol: state.protocol.merge({
      published: action.published,
      id: action.id
    })
  }) ||

  action.type === RESET_STATE
  && initialState ||

  state;

export default reducer;
