import {
  formatDistanceToNow,
  format,
} from "date-fns";

const convertEpochToDate = epoch => {
  const d = new Date(0);
  d.setUTCSeconds(epoch);
  return d;
};

const intervalToLevels = (interval, levels) => {
  const cbFun = (d, c) => {
    let bb = d[1] % c[0],
      aa = (d[1] - bb) / c[0];
    aa = aa > 0 ? aa + c[1] : '';

    return [d[0] + aa, bb];
  };

  let rslt = levels.scale.map((d, i, a) => a.slice(i).reduce((d, c) => d * c))
    .map((d, i) => ([d, levels.units[i]]))
    .reduce(cbFun, ['', interval]);
  return rslt[0];
};

const TimeLevels = {
  scale: [24, 60, 60, 1],
  units: ['d ', 'h ', 'm ', 's ']
};
const secondsToString = interval => intervalToLevels(interval, TimeLevels);

const timeBetween = (a, b) => secondsToString(a - b);

const formatDate = date => {
  const day = date.getDate();
  const year = date.getFullYear();

  return `${day}-${(date.getMonth()) + 1}-${year}`;
};

const epochDate = epoch => {
  const date = convertEpochToDate(epoch);
  return formatDate(date);
};

const getNowInEpoch = () => (new Date().getTime() / 1000);

const getTimeSinceEpoch = time =>
  `${formatDistanceToNow(
    convertEpochToDate(time)
  )} ago`;

const dateTimeConcatenate = (forDate, forTime) => {
  const resultDate = new Date(forTime)
  resultDate.setDate(forDate.getDate());
  resultDate.setMonth(forDate.getMonth());
  resultDate.setFullYear(forDate.getFullYear());
  return resultDate;
}

const epochToFormat = (epoch, formatString='dd/MM/yyyy') => {
  const date = convertEpochToDate(epoch || 0);
  return format(date, formatString);
};

const formatDateString = (date,  formatString='dd/MM/yyyy') => {
  const dateObj = new Date(date);
  return format(dateObj, formatString);
}


export {
  formatDate,
  epochDate,
  getNowInEpoch,
  getTimeSinceEpoch,
  epochToFormat,
  timeBetween,
  convertEpochToDate,
  formatDateString
};
