import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NavigationContext from 'contexts/NavigationContext';
import UserList from './UserTable/index';

export default () => (
  <NavigationContext.Provider
    value={[
      {
        label: 'All Users',
        to: '/users/list',
      }
    ]}
  >
    <Switch>
      <Route exact path="/users/list" component={UserList} />
      <Redirect to="/users/list" />
    </Switch>
  </NavigationContext.Provider>
);
