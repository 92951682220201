import React from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import Auth from 'utils/auth';
import global from 'themes/global';
import Login from './modules/Login';
import Callback from './modules/Callback';
import App from './modules/App';
import ErrorBoundary from './ErrorBoundary';
import Error from 'modules/Error';

const auth = new Auth();
global();

export default () => (
  <BrowserRouter>
    <ErrorBoundary>
      <Switch>
        <Route
          exact
          path="/login"
          render={props => <Login auth={auth} {...props} />}
        />
        <Route
          exact
          path="/callback"
          render={props => <Callback auth={auth} {...props} />}
        />
        <Route
          exact
          path="/error"
          component={Error}
        />
        <Route component={App}/>
      </Switch>
    </ErrorBoundary>
  </BrowserRouter>
);
