import React from 'react';
import { connect } from 'react-redux';
import View from './View'
import { Section } from 'Models/Protocol/models';

const mapStateToProps =
  ({ protocols }) =>
    ({
      showEditTimer: protocols.showEditTimer,
      timer: protocols.selectedTimer || Section({}),
      groups: protocols.groups
    });

export default connect(mapStateToProps)(View);
