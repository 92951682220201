import toLower from 'lodash/fp/toLower';

const sort = (array, sortBy, sortOrder='asc') =>
  array && array.sort((a, b) => {
    const aa = a[sortBy] && typeof a[sortBy] === 'object' ? a[sortBy].length :
      typeof a[sortBy] === 'string' && toLower(a[sortBy]) || a[sortBy] || -1;
    const bb = b[sortBy] && typeof b[sortBy] === 'object' ? b[sortBy].length :
      typeof b[sortBy] === 'string' && toLower(b[sortBy]) || b[sortBy] || -1;
    if (aa < bb) {
      return sortOrder === 'asc' ? -1 : 1
    }
    if (aa > bb) {
      return sortOrder === 'asc' ? 1 : -1
    }
    return 0;
  }) || [];

export {
  sort
}
