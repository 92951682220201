import { connect } from 'react-redux';
import UserGroupTable from './View';

const mapStateToProps = ({ users, app }) =>
  ({
    users: users.users,
    loading: users.loading
  });

export default connect(
  mapStateToProps
)(UserGroupTable);
