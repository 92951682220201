import React, { useEffect } from 'react';
import Loading from './index';
import Icon from 'components/Icons/Icon';
import { withStyles } from '@material-ui/core';

const styles = {
  loader: {
    margin: '48px 0 49px',
    padding: '8px'
  },
  complete: {
    fontSize: '56px',
    margin: '0 auto',
    display: 'block'
  }
}

const ModalLoader = ({ loading, complete, error, classes, completeAction }) => {
  useEffect(() => {
    // Update the document title using the browser API
    complete && completeAction && setTimeout(() => completeAction(), 500)
  }, [complete]);
  return loading
    && (
      <div className={classes.loader}>
        <Loading/>
      </div>) || complete
    && (
      <div className={classes.loader}>
        <Icon
          color="secondary"
          className={classes.complete}
          icon="check-circle"
          size="1x"
          reset
        />
      </div>
    ) || error
    && (
      <div className={classes.loader}>
        <Icon
          color="secondary"
          className={classes.complete}
          icon="times-circle"
          size="1x"
          reset
        />
      </div>
    ) || null;
}

export default withStyles(styles)(ModalLoader);
