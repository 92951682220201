import React from 'react';
import Tabs from '@material-ui/core/Tabs/Tabs';
import TabComponent from '@material-ui/core/Tab/Tab';
import Badge from 'components/Badge';
import classNames from 'classnames';

const Tab = ({ children, activeTab, tabId }) =>
  (tabId === activeTab && <div>{children}</div>) || null;

const generateTabs = (tabs, classes, color) => (
  <Tabs
    value={tabs.value}
    onClick={tabs.onClick}
    onChange={tabs.onChange}
    classes={{
      flexContainer: classes.flexContainer,
      indicator: classes.tabsIndicator
    }}
  >
    {tabs.tabs.map(t => (
      <TabComponent
        key={t.id}
        label={
          <Badge
            badgeContent={t.badge}
            color="secondary"
            altCss
            altColor={t.badgeColor}
          >
            {t.label}
          </Badge>
        }
        classes={{
          selected: classNames(classes.selectedTab, {
            [tabs.classes && tabs.classes.selectedTabText]:
              tabs.classes && tabs.classes.selectedTabText
          }),
          label: classNames(classes.tabLabel, {
            [tabs.classes && tabs.classes.tabText]:
              tabs.classes && tabs.classes.tabText
          })
        }}
      />
    ))}
  </Tabs>
);

export { Tab, generateTabs };
