import {
  PAGES
} from 'constants/index';
import PageState from './initialState';
import { PageModel } from 'Models/Page/models';

const initialState = new PageState({});

const reducer = (state = initialState, action) =>

  action.type === PAGES.SET_STATE
  && state.merge(action.state) ||

  action.type === PAGES.UPDATE_VALUE
  && state.set(action.key, action.value) ||

  action.type === PAGES.UPDATE_CURRENT_PAGE
  && state
    .setIn(['page', action.key], action.value)
    .set('pageEdited', true)
  ||

  action.type === PAGES.RECEIVE_PAGES &&
  state.set('pages', action.pages) ||

  action.type === PAGES.TOGGLE_CREATE_OR_EDIT
  && state.merge({
    showCreateOrEdit: !state.showCreateOrEdit,
    loading: false,
    page: action.page || PageModel({}),
    pageEdited: false
  }) ||

  state;

export default reducer;
