import {
  APP
} from 'constants/index';
import {
  getLoginDetails,
} from 'Models/Login';
import { store } from 'persistentStore';
import _ from 'lodash';
import { allowedUserTypes } from 'app-constants';
import errorHandler from 'utils/errorHandler';

const { dispatch } = store;

const resetState = () =>
  dispatch({
    type: APP.RESET_STATE
  });

const setAvailableLocations = availableLocations =>
  dispatch({
    type: APP.SET_AVAILABLE_LOCATIONS,
    availableLocations
  });

const clearAvailableLocations = () =>
  dispatch({
    type: APP.CLEAR_AVAILABLE_LOCATIONS
  });

const hideSideNav = () => dispatch({
  type: APP.HIDE_SIDE_NAV
});

const showSideNav = () => dispatch({
  type: APP.SHOW_SIDE_NAV
});

const updateValue = ({ key, value }) => dispatch({
  type: APP.UPDATE_VALUE,
  key,
  value
});

const setLoginContext = loginContext =>
  dispatch({
    type: APP.SET_LOGIN_CONTEXT,
    loginContext
  });

const getLoginContext = () => {
  dispatch({
    type: APP.SET_LOGIN_CONTEXT_FETCH_ID
  });
  return getLoginDetails()
    .then(data => {
      // Filter all accesses that do not grant access to the dashboard
      const relevantAccesses = _.filter(data.accesses, access =>
        _.indexOf(allowedUserTypes, access.role.name) !== -1);
      setLoginContext({
        profile: {
          ...data,
          relevantAccesses,
        }
      })
    })
    .catch(err => {
      errorHandler(err);
    });
};

export {
  setLoginContext,
  setAvailableLocations,
  hideSideNav,
  showSideNav,
  getLoginContext,
  resetState,
  clearAvailableLocations,
  updateValue,
};
