import React from 'react';
import { withStyles } from '@material-ui/core';
import SearchIcon from '../Icons/Search';
import classNames from 'classnames';
import Icon from '../Icons/Icon';
import TextField from '@material-ui/core/TextField';

const styles = ({ palette, variables }) => ({
  searchForm: {
    alignItems: 'stretch',
    backgroundColor: 'white',
    borderBottom: `1px solid ${palette.grey[300]}`,
    display: 'flex',
    height: '72px',
    padding: `22px ${variables.contentPadding}`,
    position: 'relative'
  },
  searchIconContainer: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '0.8rem'
  },
  searchBar: {
    borderStyle: 'none',
    flex: 1,
    outline: 'none',
    minWidth: 500,
    zIndex: 10
  },
  search: {
    width: '100%',
    backgroundColor: 'white',
    padding: '0 100px',
    '& div::before, & div::after': {
      display: 'none'
    }
  },
  innerContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: variables.maxWidth,
    alignItems: 'stretch',
    display: 'flex',
    padding: 0
  },
  extraPadding: {
    padding: '0 58px'
  },
  flex: {
    display: 'flex'
  },
  buttonContainer: {
    display: 'block',
    position: 'absolute',
    right: 35
  }
});

const SearchBarContainer =
  withStyles(styles)(({ classes, children }) =>
    <form className={classes.searchForm}>
      {children}
    </form>
  );

const SearchIconContainer =
  withStyles(styles)(({ classes, children }) =>
    <div className={classes.searchIconContainer}>
      {children}
    </div>
  );

const SearchBar = withStyles(styles)(({ classes, ...rest }) =>
  <TextField {...rest } className={classes.searchBar}/>
);

const FullSearchBar = withStyles(styles)(
  ({ clear, label, searchTerm, onChange, classes, fixed, buttons, extraPadding, hideSearchField }) =>
    <SearchBarContainer
      onSubmit={(e) => {
        e.preventDefault();
        clear();
      }}
      fixed={fixed}
    >
      <div className={classNames(
        classes.innerContainer,
        { [classes.extraPadding]: extraPadding }
      )}>
        {!hideSearchField &&
        <div className={classes.flex}>
          <SearchIconContainer maxInnerWidth>
            <Icon className={classes.marginRight} size={'1x'} icon="search" color="primary"/>
          </SearchIconContainer>
          <SearchBar
            onChange={onChange}
            onBlur={clear}
            placeholder={label}
            value={searchTerm || ''}
          />
        </div>
        }
        <div className={classes.buttonContainer}>
          {buttons}
        </div>
      </div>
    </SearchBarContainer>
);

export {
  SearchBarContainer,
  SearchIconContainer,
  SearchBar,
  FullSearchBar
};
