import React, { Component } from 'react';
import {
  withRouter, Switch, Route, Redirect
} from 'react-router-dom';
import Loading from 'components/Loading/index';
import NotFound from 'modules/NotFound';
import Error from 'modules/Error';
import Protocols from 'modules/Protocols';
import Users from 'modules/Users/index';
import Pages from 'modules/Pages/index';
import { withStyles } from '@material-ui/core';
import Modals from './Modals';
import styles from '../styles';
import Navigation from './Navigation';
import { getIn } from 'immutable';

class View extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      error,
      loading,
      history
    } = this.props;

    if (loading) {
      return <Loading/>
    }

    const locationArray = getIn(history, ['location', 'pathname'], '')
      .split('/')

    return (
      <div className={classes.root}>
        <Navigation locationArray={locationArray}/>
        <div className={classes.container}>
          <Switch>
            {error && <Redirect to="/error"/>}
            <Redirect exact path="/" to="/protocols"/>
            <Route path="/protocols" component={Protocols}/>
            <Route path="/protocols" component={Protocols}/>
            <Route path="/users" component={Users}/>
            <Route path="/pages" component={Pages}/>
            <Route path="/error" component={Error}/>
            <Route component={NotFound}/>
          </Switch>
        </div>
        <Modals/>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(View));
