import React, { cloneElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Title from 'components/Title/PageTitle';

const styles = ({ palette, transitions, variables, spacing: { unit } }) => ({
  paper: {
    background: 'transparent',
    margin: `${unit * 3}px ${unit * 6}px ${unit * 6}px`,
    padding: unit * 2,
    paddingBottom: unit * 6,
    borderRadius: 0,
    flexGrow: 1,
    width: `calc(100% - 44px)`,
    height: 80,
    display: 'flex',
    justifyItems: 'center'
  },
  underline: {
    borderBottom: `1px solid ${palette.grey[300]}`
  },
  paperShift: {
    transition: transitions.create('width', {
      easing: transitions.easing.easeIn,
      duration: transitions.duration.leavingScreen
    })
  },
  open: {
    color: palette.secondary.main,
    fontSize: '1.9em',
    marginLeft: unit * 2, marginTop: '-1px'
  },
  iconButton: {
    position: 'relative',
    padding: unit * 3,
    transition: transitions.create('margin', {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.leavingScreen
    })
  },
  offScreen: {
    transition: transitions.create(['width', 'display'], {
      easing: transitions.easing.easeIn,
      duration: 5000
    }),
    width: 0,
    display: 'none'
  },
  primaryActionButton: {
    marginRight: '50px',
    display: 'block',
    transition: transitions.create(['margin-right'], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.leavingScreen
    })
  },
  primaryActionButtonShifted: {
    marginRight: '0px',
    transition: transitions.create(['margin-right'], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.leavingScreen
    })
  },
  buttonContainer: {
    [variables.iEOnly]: {
      maxWidth: '58%'
    }
  },
  toggleTitle: {
    marginLeft: 30
  },
  toggleIcon: {
    marginLeft: 10
  }
});

const TitleBar =
  ({
     classes,
     title,
     toggleTitle,
     toggleFn,
     toggleOpen = true,
     primaryActionButton,
     secondaryActionButton,
     showUnderline = true,
     className,
     tabs
   }) =>
    <Paper
      elevation={0}
      className={classNames(classes.paper, className, {
        [classes.paperShift]: toggleOpen,
        [classes.underline]: showUnderline
      })}>
      <Grid container>
        <Grid container item justify="flex-start" md={4} alignItems="center">
          <Title title={title}/>
        </Grid>
        <Grid
          container
          item
          justify="flex-end"
          md={8}
          className={classes.buttonContainer}
        >
          {secondaryActionButton && cloneElement(
            secondaryActionButton,
            {
              className: classNames(
                classes.primaryActionButton,
                secondaryActionButton.props.className
              )
            }
          ) || null}
          {primaryActionButton && cloneElement(
            primaryActionButton,
            {
              className: classNames(
                classes.primaryActionButton,
                primaryActionButton.props.className,
                {
                  [classes.primaryActionButtonShifted]: toggleOpen
                }
              )
            }
          ) || null}
          {toggleFn && <Button
            color="secondary"
            className={classNames(classes.toggleTitle, {
              [classes.offScreen]: toggleOpen
            })}
            onClick={toggleFn}
          >
            {toggleTitle}
            <FontAwesomeIcon
              className={classNames(classes.toggleIcon, {
                [classes.reverse]: toggleOpen
              })
              }
              size="2x"
              icon={['fal', 'angle-double-left']}
            />
          </Button> || null}
        </Grid>
      </Grid>
    </Paper>

export default withStyles(styles)(TitleBar);
