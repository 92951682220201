import React from 'react';
import { connect } from 'react-redux';
import Dashboard from './View'

const mapStateToProps =
  ({ protocols }) => ({
    showSections: protocols.showSections,
    loading: protocols.loading,
    groups: protocols.searchTerm ? protocols.protocolsFromSearch : protocols.groups,
    searchTerm: protocols.searchTerm,
    protocolsFromSearch: protocols.protocolsFromSearch
  });

export default connect(mapStateToProps)(Dashboard);
