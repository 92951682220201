import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ palette, variables, spacing: { unit } }) => ({
  appBar: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderBottom: `1px solid ${palette.grey[300]}`,
    color: palette.primary.dark,
    display: 'flex',
    flexDirection: 'row',
    height: '82px',
    justifyContent: 'space-between',
    lineHeight: `calc(${variables.headerHeight} - 0.4rem)`,
    padding: `0 ${unit * 12}px`,
    zIndex: 2,
    marginTop: variables.appBarHeight
  },
  extendedHeight: {
    height: 120
  },
  inPage: {
    marginTop: 0
  }
});

const Header = ({ classes, children, extendedHeight, inPage }) =>
  <AppBar
    position={!inPage && 'fixed' || 'relative'}
    className={classNames(
      classes.appBar,
      {
        [classes.extendedHeight]: extendedHeight,
        [classes.inPage]: inPage
      }
    )}
  >{children}</AppBar>

export default withStyles(styles)(Header);
