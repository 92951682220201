import React from 'react';
import Popup from 'components/Dialog/Popup';
import TextInput from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';
import { updateSelectedTimer, saveTimer, toggleEditTimer } from '../actions';
import Button from '@material-ui/core/Button';
import { variants } from '../CallToAction';
import { DrawerComponent as TimerButton } from '../Sections/Timer'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = ({ palette }) => ({
  container: {
    width: '100%',
    height: '100%',
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 100px'
  },
  saveButton: {
    width: '350px',
    margin: '0px auto 0px'
  },
  formControl: {
    marginTop: 30
  },
  preview: {
    backgroundColor: palette.grey[100],
    borderTop: `1px solid ${palette.grey[300]}`,
    borderBottom: `1px solid ${palette.grey[300]}`,
    margin: '20px 0 0',
    padding: '30px 100px',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '.9em',
    fontFamily: 'Prompt',
    color: palette.grey[600],
    letterSpacing: '0.1em'
  }
});

const range = (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx);

const durations  = [ null, ...range(1, 100)];

const View =
  ({
     loading,
     classes,
     selectedStep,
     showEditTimer,
     timer
   }) =>
    timer &&
    <Popup
      title="Edit Timer"
      toggleFn={() => toggleEditTimer()}
      open={showEditTimer}
      medium
      noPadding
    >
      <div className={classes.container}>
        <TextInput
          label="Timer Text"
          value={timer.content}
          onChange={e =>
            updateSelectedTimer({ content: e.target.value })
          }
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="buttonStyle">Button Style</InputLabel>
          <Select
            value={timer.style}
            onChange={e =>
              updateSelectedTimer({ style: e.target.value })
            }
            inputProps={{
              name: 'buttonStyle',
              id: 'buttonStyle'
            }}
          >{
            variants
              .toIndexedSeq()
              .toArray()
              .map(
                style =>
                  <MenuItem
                    key={style.id}
                    value={style.id}>{style.dashboardText}
                  </MenuItem>
              )
          }
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="buttonStyle">Duration ( in minutes )</InputLabel>
          <Select
            value={timer.duration}
            onChange={e =>
              updateSelectedTimer({ duration: e.target.value })
            }
            inputProps={{
              name: 'duration',
              id: 'duration'
            }}
          >{
            durations
              .map(
                duration =>
                  <MenuItem
                    key={duration}
                    value={duration}>{duration}
                  </MenuItem>
              )
          }
          </Select>
        </FormControl>
      </div>
      <div className={classes.preview}>
        Preview
        <TimerButton
          disableClick
          content={timer.content}
          style={timer.style}
        />
      </div>
      <div className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={() => saveTimer(timer)}
        >Save Timer</Button>
      </div>
    </Popup> || null

export default withStyles(styles)(View);

