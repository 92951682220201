import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import ModalLoader from '../Loading/modalLoader';
import classNames from 'classnames';
import styles from './styles';
import { generateTabs } from 'components/Tab';

const StyledButton = withStyles({
  root: {
    width: '100%',
    borderTop: '2px solid #eee'
  },
  label: {
    width: '100%',
    margin: '0 0 -5px',
    padding: '.7em 0',
    fontSize: '1.1em'
  }
})(Button);

const FormDialog =
  ({
     buttonText,
     title,
     children,
     inProgress,
     open,
     toggleFn,
     complete,
     loading,
     index,
     medium,
     large,
     xl,
     classes,
     paperClassName,
     paperWidthMd,
     mediumDialogContent,
     noPadding,
     tabs,
     error,
     closebuttonText,
     loadingText,
     loadingCancel
   }) =>
    <div>
      {buttonText && <Button className={classes.button} onClick={toggleFn}>{buttonText}</Button>}
      <Dialog
        open={open}
        onClose={() => console.log('handle close')}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={xl && 'xl' || large && 'lg' || medium && 'md' || 'sm'}
        classes={{
          paperWidthMd: classNames(
            classes.mediumDialog,
            { [paperWidthMd]: paperWidthMd },
            { [classes.noPadding]: noPadding }
          ),
          paperWidthLg: classNames(
            classes.largeDialog,
            { [classes.noPadding]: noPadding }
          ),
          paper: classNames(
            paperClassName,
            {
              [classes.noPadding]: noPadding
            }
          )
        }}
        PaperProps={{
          classes: {
            root: 'fixedHeight'
          },
          styles: {
            paperWidthMd: {
              maxWidth: '800px'
            }
          }
        }}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        {tabs && <div className={classes.popupTabs}>{generateTabs(tabs, classes)}</div> || null}
        <DialogContent className={classNames(classes.dialogContent,
          {
            [classes.xLargeDialogContent]: xl,
            [classes.largeDialogContent]: large,
            [classes.mediumDialogContent]: medium,
            [classes.noPadding]: noPadding
          })}>
          <div className={classes.centered}>{loading && loadingText || ''}</div>
          <ModalLoader complete={complete} loading={loading} error={error}/>
          {!loading && !complete && !error && children}
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={loading && loadingCancel || toggleFn}
            color="primary"
            disabled={loading && (loading && !loadingCancel)}
          >
            {closebuttonText || 'Cancel'}
          </StyledButton>
        </DialogActions>
      </Dialog>
    </div>;

export default withStyles(styles)(FormDialog);
