import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import CloseButton from 'components/Button/CloseButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from '../styles';
import classNames from 'classnames';
import { InnerHeading } from 'components/Heading';
import _ from 'lodash';

class CollapsibleSection extends Component {
  state = {
    panelOpen: false
  }

  toggleExpansionPanel = () =>
    this.props.toggleFn ?
      this.props.toggleFn() :
      this.setState(prev => ({
        panelOpen: !prev.panelOpen
      }), () => this.props.toggleCallBack && this.props.toggleCallBack());

  render() {
    const {
      props: {
        icon,
        key,
        classes,
        title,
        primaryAction,
        secondaryAction,
        tertiaryAction,
        quaternaryAction,
        iconPrefix = 'far',
        secondTitle,
        children,
        showProgress,
        innerSection,
        hideExpander,
        titleFn,
        color
      }
    } = this;

    const panelOpen = this.props.toggleFn ? this.props.panelOpen : this.state.panelOpen;

    return (
      <ExpansionPanel
        elevation={1}
        className={classNames(
          classes.topSection,
          {
            [classes.innerSection]: innerSection,
            [classes.blueTop]: Boolean(color === 'blue'),
            [classes.tealTop]: Boolean(color === 'teal'),
            [classes.cyanTop]: Boolean(color === 'cyan')
          }
        )}
        classes={{
          expanded: classes.expanded
        }}
        key={key}
        onChange={this.toggleExpansionPanel}
        expanded={panelOpen}
      >
        <ExpansionPanelSummary
          classes={{ root: classes.expansionPanelSummaryParent }}
          expandIcon={(
            !hideExpander && <CloseButton/> || null
          )}
        >
          <Grid container spacing={24}>
            <Grid
              container
              item
              spacing={0}
              sm={6}
              alignContent="center"
            >
              {icon
              && (
                <FontAwesomeIcon
                  icon={[iconPrefix, icon]}
                  className={classes.icon}
                />
              )}
              <div className={classes.headingContainer}>
                {titleFn && <Button
                  className={classes.headerButton}
                  onClick={titleFn}
                >
                  <InnerHeading heading={title}/>
                </Button> || <InnerHeading heading={title}/>}
                <Typography
                  variant="h5"
                  className={classes.subHeading}
                >
                  {secondTitle}
                </Typography>
              </div>
            </Grid>
            <Grid
              container
              spacing={8}
              justify="flex-end"
              item
              sm={6}
            >
              <div className={classes.actionContainer}>
                {primaryAction
                && (
                  <Button
                    color="primary"
                    onClick={primaryAction.action}
                    className={classes.actionButton}
                    variant={primaryAction.variant}
                  >
                    {primaryAction.icon
                    && (
                      primaryAction.icon
                    )}
                    {primaryAction.title}
                    {primaryAction.rightIcon
                    && (
                      primaryAction.rightIcon
                    )}
                  </Button>
                )
                }
                {secondaryAction
                && (
                  <Button
                    color="primary"
                    onClick={secondaryAction.action}
                    className={classes.actionButton}
                  >
                    {secondaryAction.icon
                    && (
                      secondaryAction.icon
                    )}
                    {secondaryAction.title}
                  </Button>
                )
                }
                {tertiaryAction
                && (
                  <Button
                    color="primary"
                    onClick={tertiaryAction.action}
                    className={classes.actionButton}
                  >
                    {tertiaryAction.icon
                    && (
                      tertiaryAction.icon
                    )}
                    {tertiaryAction.title}
                  </Button>
                )
                }
                {
                  quaternaryAction
                  && (
                    <Button
                      color="primary"
                      onClick={quaternaryAction.action}
                      className={classes.actionButton}
                    >
                      {quaternaryAction.icon
                      && (
                        quaternaryAction.icon
                      )}
                      {quaternaryAction.title}
                    </Button>
                  )
                }
              </div>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        {showProgress && !children &&
        <LinearProgress
          color="primary"
          className={classNames(classes.linearProgress, {
            [classes.hidden]: !panelOpen
          })}
        />
        }
        <div>
          {children}
        </div>
        }
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(CollapsibleSection);
