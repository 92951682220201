import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import variables from './variables';

const headingDefault = {
  fontFamily: 'Prompt',
  fontWeight: 500
};

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    fontFamily: ['Nunito', 'sans-serif'],
    h1: {
      fontFamily: 'Prompt',
      fontSize: '1.7em',
      fontWeight: 400
    },
    h3: {
      fontSize: '1.5em',
      fontWeight: 400
    },
    h4: {
      fontSize: '1.3em',
      color: indigo
    },
    h6: {
      ...headingDefault,
      fontSize: '1em'
    },
    caption: {
      ...headingDefault,
      fontSize: '1.2em'
    },
    button: {
      ...headingDefault,
      fontSize: '1.4em'
    },
    title: {
      ...headingDefault,
      fontSize: '2.6em'
    }
  },
  palette: {
    primary: {
      main: '#275DAA',
      dark: '#00347a',
      light: '#618adc'
    },
    secondary: {
      main: '#bc0000',
      dark: '#840000',
      light: '#f74b2e'
    },
    background: {
      default: '#F8F8F8'
    },
    red: '#d2232a',
    error: red,
    success: '#2DCC70',
    green,
    teal: {
      main: '#279092',
      light: '#60c1c2',
      dark: '#006264',
      transparent: 'rgba(39, 144, 146, .1)'
    },
    cyan: {
      main: '#5AB7D5',
      light: '#8fe9ff',
      dark: '#1887a4',
      transparent: 'rgba(90, 183, 213, .1)'
    },
    blue: {
      main: '#275DAA',
      dark: '#00347a',
      light: '#618adc',
      transparent: 'rgba(39, 93, 170, .1)'
    },
    lightBlue: '#e4edf9',
    warning: {
      light: orange[300],
      main: orange[700],
      dark: orange[900]
    },
    fcBlack: 'rgba(0, 0, 0, 0.87)',
    fcGray: 'rgba(0, 0, 0, 0.54)',
    overlay: 'rgba(20, 23, 47, 0.5)',
    lightPink1: 'rgba(255,13,101,0.14)',
    darkPink1: 'rgba(191,0,69,0.29)',
    darkPink2: '#FF4F8F',
    redPink1: '#E50053',
    darkGray1: '#464646',
    darkGray2: '#999',
    negative: '#d50000',
    positive: '#2dcc70',
    neutral: '#e18713'
  },
  variables: {
    iEOnly:
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)',
    headerHeight: '9.6rem',
    appBarHeight: 84,
    appBarClearance: '84px',
    contentPadding: '4rem',
    drawerWidth: '350px',
    ...variables
  },
  spacing: {
    unit: 4
  },
  /* -------------------------Use Over-rides to easily theme all components------- */
  overrides: {
    MuiDialogTitle: {
      root: {
        textAlign: 'center',
        margin: '1.5em 0 0',
        fontSize: '1.2em'
      }
    },
    MuiDialogActions: {
      root: {
        margin: 0
      }
    },
    MuiInputLabel: {
      shrink: {
        fontSize: '.8em',
        color: '#abaac1'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '1.1em',
        "&$focused": {
          "fontSize": "1.1em"
        }
      }
    },
    MuiSelect: {
      root: {
        minWidth: '200px'
      }
    },
    MuiButton: {
      root: {
        fontSize: '.8em',
        fontWeight: 500,
        fontFamily: 'Prompt, sans-serif',
        padding: '1.2em',
        // boxShadow: 'none!important',
        "&$outlined": {
          padding: '12px 48px'
        },
        cursor: 'pointer'
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: '.8em'
      }
    },
    MuiTableCell: {
      body: {
        fontSize: '1em',
        padding: 4
      },
      head: {
        fontSize: '1em',
        fontFamily: 'Prompt, sans-serif',
        fontWeight: 500,
        textTransform: 'uppercase',
        padding: 4
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '1em'
      }
    },
    MuiSnackBar: {
      root: {
        maxHeight: '0px'
      }
    },
    MuiSnackbarContent: {
      message: {
        textAlign: 'center',
        width: '100%',
        position: 'absolute',
        top: '2px',
        left: 0,
        right: 0,
        fontSize: '1.3em'
      }
    },
    MuiIcon: {
      root: {
        // boxShadow: 'none'
      }
    },
    MuiPaper: {
      root: {
        // boxShadow: 'none!important'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '.8em'
      }
    }
  }
};

export default theme;
