import Header from '../Header';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { toggleAreYouSureModal } from '../../modules/Modals/actions';
import Icon from '../Icons/Icon';
import { generateTabs } from '../Tab';
import classNames from 'classnames';
import React from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core';
import tabStyles from 'components/Tab/styles';

const styles = ({ palette }) => ({
  ...tabStyles({ palette }),
  h3: {
    display: 'flex',
    color: palette.grey[600],
    fontSize: '.8em',
    textAlign: 'left',
    textTransform: 'uppercase',
    fontFamily: 'Prompt',
    fontWeight: 500,
    letterSpacing: '0.1em'
  },
  h1: {
    color: palette.grey[800],
    fontSize: '1.2em',
    display: 'flex',
    fontWeight: 300,
    textAlign: 'left',
    marginTop: 0
  },
  justified: {
    display: 'flex',
    alignContent: 'center',
    width: '66%',
    height: '100%',
    lineHeight: '5px'
  },
  smallerH1: {
    fontSize: '1.5em',
    display: 'block',
    lineHeight: '1.2em'
  },
  longLine: {
    fontSize: '1.2em',
    marginTop: 14
  },
  veryLongLine: {
    fontSize: '1em',
    marginTop: 16
  },
  iconButton: {
    position: 'relative',
    height: 50,
    width: 50,
    margin: 11,
    marginTop: 16
  },
  relative: {
    position: 'relative',
    lineHeight: 'normal',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});

export const Title = withStyles(styles)(({ heading, subHeading, classes }) =>
  <div className={classes.relative}>
    <Typography
      variant="h1"
      className={
        classNames(classes.h1, {
          [classes.smallerH1]: subHeading,
          [classes.longLine]: heading.length > 50,
          [classes.veryLongLine]: heading.length > 65
        })
      }
    >
      {heading}
    </Typography>
    {subHeading && <Typography
      variant="h3"
      className={classes.h3}
    >{subHeading}</Typography>}
  </div>
);

const InPageNav =
  ({
     classes,
     checkOnNavigation,
     toggleFn,
     titleComponent,
     title,
     subHeading,
     tabs,
     inPage,
     smallerTitle,
     rightDetail
   }) =>
    <Header inPage={inPage}>
      <div className={classes.justified}>
        <IconButton
          className={classes.iconButton}
          disableRipple={false}
          onClick={() => checkOnNavigation ? toggleAreYouSureModal(() => toggleFn()) : toggleFn()}
        >
          <Icon
            color="lightGrey"
            icon="chevron-left"
            iconType="fal"
            size={'1x'}
          />
        </IconButton>
        {
          titleComponent && titleComponent ||
          <Title
            heading={title}
            subHeading={subHeading}
            smallerTitle={smallerTitle}
          />
        }
      </div>
      {rightDetail &&
      <div className={classes.rightDetail}>
        {rightDetail}
      </div>
      }
      {tabs && <div className={classes.tabs}>{generateTabs(tabs, classes)}</div> || null}
    </Header>;

export default withStyles(styles)(InPageNav);
