import React from 'react';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css';

import FroalaEditor from 'react-froala-wysiwyg';

const OverrideStyles = () => (
  <style>
    {`
    .fr-toolbar {
      z-index: 10000
    }
    `}
  </style>
);
const { REACT_APP_API_URL, REACT_APP_FROALA_LICENSE_KEY } = process.env;
console.log( REACT_APP_FROALA_LICENSE_KEY, '..........froala key')

const Editor = ({ value, onChange, toolBarInline = true, height }) =>
  <div>
    <OverrideStyles/>
    <FroalaEditor
      config={{
        toolbarInline: Boolean(toolBarInline),
        imageUploadURL: `${REACT_APP_API_URL}/upload`,
        fileUpload: false,
        videoUpload: false,
        autoFocus: true,
        height: height,
        attribution: false,
        key: REACT_APP_FROALA_LICENSE_KEY,
        charCounterCount: false,
        toolbarButtons: ['bold', 'italic', 'underline', 'align', 'indent', 'outdent', '-', 'insertImage', 'insertLink', 'undo', 'redo'],
        events: {
          'froalaEditor.image.uploaded': (e, editor, response) => {
            const json = JSON.parse(response);
            editor.image.insert(
              json.url,
              false,
              null,
              editor.image.get(),
              json
            );
            editor.events.focus();
            return false;
          }
        },
        requestHeaders: {
          authorization: `Bearer ${localStorage.getItem('access-token')}`
        }
      }}
      autoFocus={true}
      model={value}
      onModelChange={onChange}
    />
  </div>

export default Editor;
