import { SNACKBAR } from '../../constants';
import { store } from 'persistentStore';

const { dispatch } = store;

const toggleSnackBar = (message, variant) => dispatch({
  type: SNACKBAR.TOGGLE,
  message,
  variant
});

const hideSnackBar = () => dispatch({
  type: SNACKBAR.HIDE
});

const popSnackBar = (message, variant, timeIn = 500, timeOut = 2000) =>
  setTimeout(() => {
    dispatch({
      type: SNACKBAR.SHOW,
      message,
      variant
    });
    return setTimeout(() => {
      hideSnackBar()
    }, timeOut);
  }, timeIn);

export default toggleSnackBar;
export {
  popSnackBar,
  hideSnackBar
}
