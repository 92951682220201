import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import Editor from 'components/Editor';
import { updateSelectedStepSection } from '../actions';

const styles = ({ palette, variables }) => ({
  stepPaper: {
    padding: 20,
    borderRadius: 8,
    width: variables.sectionEditorWidth,
    minHeight: 50
  }
});

const DrawerComponent = withStyles(styles)(
  ({
     classes,
     content,
     id
   }) =>
    <Paper
      elevation={1}
      classes={{
        elevation1: classes.stepPaper
      }}
    >
      <Editor
        value={content}
        onChange={value =>
          updateSelectedStepSection(id, 'content', value)}
      />
    </Paper>
);

export {
  DrawerComponent
}
