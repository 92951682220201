export default ({ spacing, palette }) =>
  ({
    close: {
      padding: spacing.unit / 2
    },
    snack: {
      '& > div': {
        borderTop: `6px solid ${palette.success}`,
        maxWidth: 800,
        width: 509,
        paddingBottom: 20,
        fontSize: '.8em',
        borderRadius: '8px 8px 0 0'
      }
    },
    error: {
      '& > div': {
        borderTop: `6px solid ${palette.error[500]}`
      }
    },
    icon: {
      marginRight: '9px'
    }
  });
