import { OrderedMap, Seq, Map } from 'immutable';

const mapFromArray = (array, Model, key = 'id') =>
  Map(Seq(array).map(v => [v[key], new Model(v)]));

const orderedMapFromArray = (array, Model, key = 'id') =>
  OrderedMap(Seq(array).map(v => [v[key], new Model(v)]));

export {
  orderedMapFromArray,
  mapFromArray
}
