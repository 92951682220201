import React from 'react';
import Popup from 'components/Dialog/Popup';
import TextInput from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';
import { updateSelectedAlert, saveAlert, toggleEditAlert } from '../actions';
import Button from '@material-ui/core/Button';
import { variants } from '../CallToAction';
import { DrawerComponent as AlertButton } from '../Sections/Alert'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = ({ palette }) => ({
  container: {
    width: '100%',
    height: '100%',
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 100px'
  },
  saveButton: {
    width: '350px',
    margin: '0px auto 0px'
  },
  formControl: {
    marginTop: 30
  },
  preview: {
    backgroundColor: palette.grey[100],
    borderTop: `1px solid ${palette.grey[300]}`,
    borderBottom: `1px solid ${palette.grey[300]}`,
    margin: '20px 0 0',
    padding: '30px 100px',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '.9em',
    fontFamily: 'Prompt',
    color: palette.grey[600],
    letterSpacing: '0.1em'
  },
});

const View =
  ({
     loading,
     classes,
     selectedStep,
     showEditAlert,
     alert
   }) =>
    alert &&
    <Popup
      title="Edit Alert"
      toggleFn={() => toggleEditAlert()}
      open={showEditAlert}
      medium
      noPadding
    >
      <div className={classes.container}>
        <TextInput
          label="Alert Text"
          value={alert.content}
          onChange={e =>
            updateSelectedAlert({ content: e.target.value })
          }
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="buttonStyle">Button Style</InputLabel>
          <Select
            value={alert.style}
            onChange={e =>
              updateSelectedAlert({ style: e.target.value })
            }
            inputProps={{
              name: 'buttonStyle',
              id: 'buttonStyle'
            }}
          >{
            variants
              .toIndexedSeq()
              .toArray()
              .map(
                style =>
                  <MenuItem
                    key={style.id}
                    value={style.id}>{style.dashboardText}
                  </MenuItem>
              )
          }
          </Select>
        </FormControl>
      </div>
      <div className={classes.preview}>
        Preview
        <AlertButton
          disableClick
          content={alert.content}
          style={alert.style}
        />
      </div>
      <div className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={() => saveAlert(alert)}
        >Save Alert</Button>
      </div>
    </Popup> || null

export default withStyles(styles)(View);

