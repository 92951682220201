import {
  SNACKBAR
} from 'constants/index';
import initialState from './initialState';

const reducer = (state = initialState, action) =>
  action.type === SNACKBAR.TOGGLE
  && {
    ...state,
    open: !state.open,
    message: !state.show && action.message,
    variant: !state.show && action.variant
  } ||

  action.type === SNACKBAR.SHOW
  && {
    ...state,
    open: true,
    message: action.message,
    variant: action.variant
  } ||

  action.type === SNACKBAR.HIDE
  && {
    ...state,
    ...initialState
  }

  || state;

export default reducer;
