import { Record } from 'immutable';
import { getName } from 'utils/helpers';

class UserModel extends Record({
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  lastLoggedIn: 0,
  createdAt: 0
}) {
  get fullName() {
    return getName({ first_name: this.firstName, last_name: this.lastName })
  }
};

export {
  UserModel
}
