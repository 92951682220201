import { connect } from 'react-redux';
import AreYouSureModal from './View';

const mapStateToProps = ({ modals }) =>
  ({
    showAreYouSureModal: modals.showAreYouSureModal,
    callBack: modals.areYouSureCallBack,
    areYouSureString: modals.areYouSureString,
    buttonText: modals.buttonText,
    areYouSureTitle: modals.areYouSureTitle
  });

export default connect(
  mapStateToProps
)(AreYouSureModal);
