import { injectGlobal } from 'styled-components';
import styledNormalize from 'styled-normalize';

export default () => injectGlobal`
  ${styledNormalize}
  
  .rg-icon-class {
    height: 100%;
    letter-spacing: 0.1em;
    color: blue!important;
  }
`;
