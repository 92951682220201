import React from 'react';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import Icon from 'components/Icons/Icon';
import Button from '@material-ui/core/Button';
import { selectStep } from '../actions';
import { RemoveStep } from '../components';
import { maybeTruncateName } from 'utils/helpers';
import { CallToActionButton } from '../CallToAction';
import { OrderedMap } from 'immutable';

const styles = ({ palette }) => ({
  stepPaper: {
    fontFamily: 'Nunito',
    color: palette.grey[800],
    marginBottom: 30,
    borderRadius: 8
  },
  callToAction: {
    textTransform: 'uppercase',
    padding: '12px 20px',
    borderTop: `1px solid ${palette.grey[300]}`,
    borderLeft: `8px solid ${palette.secondary.main}`,
    fontFamily: 'Prompt',
    borderRadius: 0,
    fontWeight: 500,
    color: palette.secondary.main,
    fontSize: '.8em'
  },
  continue: {
    borderLeft: `8px solid ${palette.primary.main}`,
    color: palette.primary.main
  },
  title: {
    padding: 20,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    textTransform: 'none',
    fontWeight: 400,
    fontFamily: 'Nunito',
    fontSize: '1em'
  },
  selected: {
    backgroundColor: palette.primary.main,
    color: 'white',
    '&:hover': {
      color: palette.primary.main
    }
  }
});

const positives = ['blueFilled', 'blueOutlined'];

const CallToAction =
  withStyles(styles)(
    ({
       title,
       classes,
       style
     }) =>
      <Paper
        elevation={0}
        classes={{
          elevation0:
            classNames(classes.callToAction, { [classes.continue]: positives.includes(style) })
        }}
      >{maybeTruncateName(title, 30)}</Paper>
  );

const Title =
  withStyles(styles)(
    ({ classes, children, step, selected }) =>
      <Button
        fullWidth
        className={classNames(classes.title, { [classes.selected]: selected })}
        onClick={() => selectStep(step.uuid)}
      >{children}</Button>
  );

const Step =
  ({
     step,
     classes,
     selected
   }) => {
    return <Paper
      elevation={2}
      classes={{
        elevation2: classes.stepPaper
      }}
    >
      <Title
        step={step}
        selected={selected}
      >
        {maybeTruncateName(step.title, 36) || 'Untitled Step'}
        <RemoveStep id={step.uuid} selected={selected}/>
      </Title>
      {(step.callsToAction || OrderedMap({}))
        .toIndexedSeq()
        .toArray()
        .map(
          call => <CallToAction style={call.style} title={call.title}/>
        )}
    </Paper>
  }

export default withStyles(styles)(Step);
