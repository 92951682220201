const protocolValid = protocol => {
  if (!protocol.title) {
    return false;
  }
  const stepsValid = protocol.steps.reduce(
    (acc, step) => {
      if (!acc) {
        return false
      }
      if (!step.title) {
        return false
      }
      if (step.sections.size === 0) {
        return false
      }
      const checkSectionContent = step.sections.reduce(
        (innerAcc, section) =>
          section.type === 'richText' && !section.content ? false : true, true
      )
      if (!checkSectionContent) {
        return false
      }
      return true;
    }, true
  );
  if (!stepsValid) {
    return false;
  }
  return true;
}

export {
  protocolValid
}
