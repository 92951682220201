import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import CloseButton from 'components/Button/CloseButton';
import styles from '../styles';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import classNames from 'classnames';
import _ from 'lodash';

class CollapsibleSection extends Component {
  state = {
    panelOpen: false
  }

  toggleExpansionPanel = () =>
    _.has(this.props, 'toggleFn') ?
      this.props.toggleFn() :
      this.setState(prev => ({
        panelOpen: !prev.panelOpen
      }), () => this.props.toggleCallBack && this.props.toggleCallBack());

  render() {
    const {
      props: {
        icon,
        key,
        classes,
        data,
        title,
        primaryAction,
        secondaryAction,
        tertiaryAction,
        iconPrefix = 'fal',
        subTitle,
        showProgress,
        blueTitle,
        titleFn,
        hideExpander
      }
    } = this;

    const panelOpen = _.has(this.props, 'toggleFn') ? this.props.panelOpen : this.state.panelOpen;

    const children = React.Children.map(
      this.props.children, child => React.cloneElement(child, {
        open: panelOpen,
        data
      }));

    return (
      <ExpansionPanel
        elevation={5}
        className={classes.topSection}
        key={key}
        onChange={this.toggleExpansionPanel}
        expanded={panelOpen || false}
      >
        <ExpansionPanelSummary
          classes={
            {
              root: classes.expansionPanelSummaryRoot,
              content: classNames(
                { [classes.hideExpander]: hideExpander }
              )
            }
          }
          expandIcon={(
            !hideExpander && <CloseButton/>
          )}
        >
          <Grid
            container
            spacing={0}
            className={classes.outerContainer}
          >
            <Grid
              container
              item
              spacing={0}
              sm={6}
              alignItems="center"
            >
              {icon
              && (
                <div className={classes.iconWrapper}>
                  <FontAwesomeIcon
                    icon={[iconPrefix, icon]}
                    className={classes.icon}
                  />
                </div>
              )}
              <div className={classes.headingContainer}>
                <Typography
                  variant="h3"
                  className={classNames(
                    classes.heading,
                    {
                      [classes.onlyHeading]: !subTitle,
                      [classes.blueTitle]: blueTitle
                    }
                  )}
                >
                  {titleFn && <Button
                    className={classes.titleFn}
                    onClick={(e) => {
                      e.stopPropagation();
                      titleFn()
                    }}>{title}</Button> || title}
                </Typography>
                {subTitle &&
                <Typography
                  variant="h5"
                  className={classes.subHeading}
                >
                  {subTitle}
                </Typography>
                }
              </div>
            </Grid>
            <Grid
              container
              spacing={8}
              justify="flex-end"
              item
              sm={6}
            >
              <div className={classes.actionContainer}>
                {primaryAction
                && (
                  <Button
                    color={primaryAction.color || "secondary"}
                    onClick={primaryAction.action}
                    className={classes.actionButton}
                  >
                    {primaryAction.icon
                    && (
                      primaryAction.icon
                    )}
                    {primaryAction.title}
                  </Button>
                )
                }
                {secondaryAction
                && (
                  <Button
                    color={secondaryAction.color || 'primary'}
                    onClick={secondaryAction.action}
                    className={classes.actionButton}
                  >
                    {secondaryAction.icon
                    && (
                      secondaryAction.icon)}
                    {secondaryAction.title}
                  </Button>
                )
                }
                {tertiaryAction
                && (
                  <Button
                    color="primary"
                    onClick={tertiaryAction.action}
                    className={classes.actionButton}
                  >
                    {tertiaryAction.icon
                    && (
                      tertiaryAction.icon)}
                    {tertiaryAction.title}
                  </Button>
                )
                }
              </div>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        {showProgress && !children &&
        <LinearProgress
          color="primary"
          className={classNames(
            classes.linearProgress, {
              [classes.hidden]: !this.state.panelOpen
            })}
        />
        }
        <div className={classes.childrenContainer}>
          {children}
        </div>
        }
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(CollapsibleSection);
