import React from 'react';
import { connect } from 'react-redux';
import View from './View'

const mapStateToProps =
  ({ protocols }) =>
    ({
      showEditAlert: protocols.showEditAlert,
      protocol: protocols.protocol,
      alert: protocols.selectedAlert,
      groups: protocols.groups
    });

export default connect(mapStateToProps)(View);
