import React from 'react';
import { withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const styles = ({ palette, spacing: { unit } }) => ({
  icon: {
    fontSize: '1.2em',
    color: palette.grey[500],
    margin: `${unit * 3}px`
  }
});

const CloseButton = ({
  label, action, classes, className, ...rest
}) =>
  (
    <FontAwesomeIcon
      icon={['fal', 'chevron-circle-down']}
      className={classNames(classes.icon, className)}
      {...rest}
    />
  );

export default withStyles(styles)(CloseButton);
