import baseStyles from '../styles';

export default ({ palette, variables }) => ({
  ...baseStyles({ palette, variables }),
  addNew: {
    border: `1px dashed ${palette.grey[300]}`,
    backgroundColor: palette.grey[100],
    display: 'flex',
    padding: 20,
    width: variables.sectionEditorWidth,
    borderRadius: 8,
    marginTop: 20
  },
  smaller: {
    width: 350
  },
  sectionMenu: {
    backgroundColor: palette.grey[900],
    color: 'white',
    width: 170,
    textTransform: 'uppercase',
    fontSize: '.7em',
    opacity: 1,
    display: 'block',
    textAlign: 'center',
    fontWeight: 600,
    height: 15,
    '&:hover': {
      color: palette.primary.light
    }
  },
  menuPaper: {
    borderRadius: '0 0 8px 8px',
    marginTop: -120,
    marginLeft: 70,
    borderTop: `5px solid ${palette.primary.main}`,
    overflowX: 'visible',
    overflowY: 'visible',
    '& > *': {
      backgroundColor: palette.grey[900]
    },
    '&:after': {
      top: '100%',
      left: '50%',
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'transparent',
      borderTopColor: palette.grey[900],
      borderWidth: 6,
      marginLeft: -6
    },
    '&:before': {
      top: '100%',
      left: '50%',
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'transparent',
      borderTopColor: palette.grey[900],
      borderWidth: 12,
      marginLeft: -12
    }
  },
  removeIcon: {
    display: 'block',
    margin: '2px 0'
  },
  removeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 15,
    cursor: 'pointer',
    '&:hover *': {
      color: palette.primary.main
    }
  },
  removeLine: {
    fontSize: '.8em',
    color: palette.grey[300],
    textTransform: 'uppercase',
    fontFamily: 'Prompt',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },
  removeText: {
    marginLeft: 22,
    position: 'absolute',
    fontSize: '.8em',
    letterSpacing: '.1em'
  },
  deleteMenu: {
    marginTop: -82,
    marginLeft: -92
  },
  sectionButton: {
    margin: '20px 0',
    width: variables.sectionEditorWidth
  },
  iconSize: {
    fontSize: '1.2em'
  },
  marginLeft10: {
    marginLeft: 10
  },
  marginRight10: {
    marginRight: 10
  },
  saveButton: {
    width: 300,
    marginLeft: 30
  }
})
