import React from 'react';
import { Redirect } from 'react-router-dom';
import Loading from 'components/Loading';
import Routes from './Routes/index';
import errorHandler from 'utils/errorHandler';

const profile = {
  'name': 'che'
}

const View =
  ({
     loading, error
   }) => {
    if (loading) return <Loading/>;
    if (error) {
      if (error.message === 'Request failed with status code 401') {
        return <Redirect to="/login"/>;
      }
      errorHandler(error);
      return <Redirect to="/error"/>;
    }
    return <Routes profile={profile}/>;
  }

export default View;
