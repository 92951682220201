import SectionComponent from '../Sections';
import { NewSection, SaveDrawer } from './components';
import { AddNew } from '../components';
import { toggleCreateOrEditCallToAction, updateSelectedStep } from '../actions';
import { CallToActionButton } from '../CallToAction';
import Paper from '@material-ui/core/Paper/Paper';
import React from 'react';
import { withStyles } from '@material-ui/core';
import { TextFieldComponent } from './View';
import { sort } from 'utils/sorting';

const styles = ({ variables, palette }) => ({
  stepBackground: {
    marginLeft: variables.extraProtocolWidth,
    marginTop: variables.topClearance,
    padding: 30,
    paddingBottom: 100
  },
  info: {
    color: palette.grey[600],
    fontSize: '.8em',
    fontWeight: 500,
    fontFamily: 'Prompt',
    letterSpacing: '.1em',
    textAlign: 'center',
    margin: '15px',
    width: variables.sectionEditorWidth,
    textTransform: 'uppercase'
  },
  marginBottom: {
    marginBottom: 30
  }
});

const StepField = withStyles(styles)(
  ({ title, classes }) =>
    <TextFieldComponent
      value={title}
      placeholder={'Name this Step...'}
      onChange={e => updateSelectedStep('title', e.target.value)}
      className={classes.marginBottom}
    />
);

const ActiveStep =
  ({
     protocolEdited,
     selectedStep,
     protocol,
     classes
   }) =>
    <Paper
      elevation={0}
      classes={{
        elevation0: classes.stepBackground
      }}
    >
      <div>
        <StepField title={selectedStep.title}/>
        {
          sort(
            (selectedStep.sections)
              .toIndexedSeq()
              .toArray(), 'order'
          )
            .map(
              section =>
                <SectionComponent
                  key={section.id}
                  section={section}
                  index={section.order}
                  id={section.id}
                />
            )
        }
        <NewSection sectionCount={selectedStep.sections.size}/>
        <div className={classes.info}>Calls To Action</div>
        <AddNew
          type={'Call to Action'}
          color={"primary"}
          onClick={() => toggleCreateOrEditCallToAction(null, selectedStep.callsToAction.size)}
        />
        {
          sort((selectedStep.callsToAction || [])
            .toIndexedSeq()
            .toArray(), 'order'
          )
            .map(
              call =>
                <CallToActionButton
                  key={call.id}
                  call={call}
                  index={call.order}
                  id={call.id}
                  showActions
                />
            )}
      </div>
      <SaveDrawer
        protocol={protocol}
        protocolEdited={protocolEdited}
      />
    </Paper>

export default withStyles(styles)(ActiveStep);
