import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import { FullSearchBar } from '../Search';
import Button from '@material-ui/core/Button/Button';
import Icon from 'components/Icons/Icon';

const Page = ({ classes, children, search }) =>
  <div className={classNames(
    classes.root
  )}>
    {search &&
    <FullSearchBar
      onChange={search.searchFn}
      buttons={search.filters &&
      <Button
        onClick={search.filters.toggle}
        color="secondary"
      >
        {search.filters.open ? (
          <Fragment>
            <Icon
              color="grey"
              icon="times-circle"
              className={classes.filterIcon}
            />
            {' '}
            <span className={classes.closeFiltersText}>CLOSE FILTERS</span>
          </Fragment>
        ) : (
          <Fragment>
            <Icon
              color="secondary"
              icon="sliders-h"
              iconType="far"
            />
            {' '}
            EDIT FILTER
          </Fragment>
        )}
      </Button>}
      {...search}
    />
    }
    <div className={classes.container}>
      <div className={classNames(
        classes.content,
        classes.contentShift
      )}
      >
        {children}
      </div>
    </div>
  </div>

export default withStyles(styles)(Page);
