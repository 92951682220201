import { injectGlobal } from 'styled-components';
import styledNormalize from 'styled-normalize';
import iconCss from './icons';

export default () => injectGlobal`
  ${styledNormalize}
  ${iconCss}
  html,
  body {
    height: 100%;
    min-width: 1060px;
  }
  * {  
    box-sizing: border-box;
  }

  html {
    font-family: Nunito, sans-serif;
    line-height: 1.5;
  }

  body {
    background-color: #f5f5f5;
    font-size: 1rem;
    margin: 0;

    &.no-scroll {
      overflow: hidden;
    }
  }

  button,
  input {
    overflow: visible;
  }


  a {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  table {
    font-family: 'Raleway', sans-serif;
  }
 #open_intercom {
 display:none
 }
`;
