import { USERS } from 'constants/index';
import { store } from 'persistentStore';
import { getUsers, editUser, addUser, deleteUser } from 'Models/User';
import errorHandler from 'utils/errorHandler';
import { popSnackBar } from '../../components/Snackbar/actions';

const { dispatch } = store;

const setState = state =>
  dispatch({
    type: USERS.SET_STATE,
    state
  });

const updateUser = (key, value) =>
  dispatch({
    type: USERS.UPDATE_USER,
    key,
    value
  });

const toggleCreateOrEdit = user =>
  dispatch({
    type: USERS.TOGGLE_CREATE_OR_EDIT,
    user
  });

const fetchUsers = () => {
  setValue({ key: 'loading', value: true });
  getUsers()
    .then(users => {
      dispatch({
        type: USERS.RECEIVE_USERS,
        users
      })
    })
}

const saveUser = user => {
  setValue({ key: 'loading', value: true });
  return (user.id ? editUser(user) : addUser(user))
    .then(() => {
      dispatch({
        type: USERS.SAVE_USER
      });
      popSnackBar('User Saved');
      return fetchUsers();
    })
    .catch(err => {
      dispatch({
        type: USERS.SAVE_USER
      });
      popSnackBar('There was a problem saving the user', 'error');
      errorHandler(err);
      return fetchUsers();
    })
};

const removeUser = id => {
  setValue({ key: 'loading', value: true });
  return deleteUser(id)
    .then(() => {
      setValue({ key: 'loading', value: false });
      popSnackBar('Successfully removed user');
      return fetchUsers();
    })
    .catch(err => {
      errorHandler(err);
      setValue({ key: 'loading', value: false });
      popSnackBar('There was a problem removing the user', 'error');
      return fetchUsers();
    })
};

const setValue = ({ key, value }) =>
  dispatch({
    type: USERS.SET_VALUE,
    key,
    value
  });

export {
  setState,
  setValue,
  fetchUsers,
  updateUser,
  toggleCreateOrEdit,
  saveUser,
  removeUser
};
