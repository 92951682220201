import React from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import classNames from 'classnames';
import FullLogo from 'modules/App/Logo/FullLogo';
import Grid from '@material-ui/core/Grid/Grid';
import InPagePrimaryNav from '../Section/InPageBlueNav';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { Link, withRouter } from 'react-router-dom';
import { SideSection } from './components';
import Title from '../Title/PageTitle';

const View =
  ({
     children,
     loading,
     classes,
     location: { pathname },
     loginContext,
     showFullLogo,
     beforeNavigate,
     history,
     inModal,
     paddingTop,
     blueNav,
     title,
     goBack,
     rightDetail,
     noMarginLeft
   }) => {
    return <div>
      <AppBar
        position="fixed"
        className={
          classNames(
            classes.appBar,
            classes.resetLeft
          )}
      >
        <div className={classes.logoContainer}>
          <Link to="/">
            <FullLogo beforeNavigate={beforeNavigate}/>
          </Link>
        </div>
        <Grid container className={classes.appBarCont}>
          <Grid item container md={8}>
          </Grid>
          <SideSection
            beforeNavigate={beforeNavigate}
            history={history}
          />
        </Grid>
      </AppBar>
      <div className={classes.root}>
        {blueNav &&
        <InPagePrimaryNav
          toggleFn={goBack}
          title={title}
          inPage
          smallerTitle
          rightDetail={rightDetail}
        />
        }
        <div className={classNames(classes.templateRoot, { [classes.noMarginLeft]: noMarginLeft})}>
          {title && <Title
            title={title}
            className={classes.tableTitle}
          />
          }
          {children}
        </div>
      </div>
    </div>
  }

export default withStyles(styles)(withRouter(View));
