export default {
  fcBlack: 'rgba(0, 0, 0, 0.87)',
  fcGray: 'rgba(0, 0, 0, 0.54)',

  primary: '#ff0d65',
  lightPink1: 'rgba(255,13,101,0.14)',
  darkPink1: 'rgba(191,0,69,0.29)',
  darkPink2: '#FF4F8F',
  redPink1: '#E50053',
  blue: '#8094C0',
  secondary: '#3e4d71',
  darkGray1: '#464646',
  darkGray2: '#999',
  lightGray1: '#ddd',
  lightGray2: '#f9f9f9',
  lightGray3: '#f0f0f0',
  negative: '#d50000',
  positive: '#2dcc70',
  neutral: '#e18713',
  overlay: 'rgba(20, 23, 47, 0.5)',

  headerHeight: '9.6rem',
  topClearance: 85,
  navigationWidth: 256,
  contentPadding: '4rem',
  primaryFont: 'font-family: Nunito, sans-serif;',
  secondaryFont: 'font-family: \'Prompt\', sans-serif',
  maxWidth: 1600,
  extraProtocolWidth: 150,
  sectionEditorWidth: 500
};
