export default ({ palette, transitions, variables, spacing: { unit } }) =>
  ({
    root: {
      overflowX: 'hidden'
    },
    blueTitle: {
      color: palette.primary.main
    },
    shiftDown: {
      marginTop: variables.appBarHeight
    },
    genericExpansionRoot: {
      padding: 10
    },
    expansionPanelSummaryParent: {
      minHeight: 70
    },
    hideExpander: {
      '& > :last-child': {
        paddingRight: 4
      }
    },
    expansionPanelSummaryRoot: {
      paddingLeft: 4
    },
    headerButton: {
      padding: 0,
      textTransform: 'none',
      margin: 0,
      fontSize: '1em',
      minHeight: 0
    },
    container: {
      display: 'flex'
    },
    titleFn: {
      padding: 0,
      color: palette.primary.dark,
      textTransform: 'none'
    },
    drawer: {
      width: variables.drawerWidth,
      flexShrink: 0
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: `0 ${unit * 2}px`,
      justifyContent: 'flex-end'
    },
    drawerPaper: {
      width: variables.drawerWidth,
      top: variables.appBarHeight,
      padding: `${unit}px ${unit * 4}px 100px`,
      bottom: 0,
      zIndex: 0
    },
    underMenu: {
      top: `calc(${variables.appBarHeight} * 2 )`
    },
    content: {
      position: 'relative',
      minHeight: '800px',
      flexGrow: 1,
      // padding: unit * 3,
      transition: transitions.create('margin', {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen
      }),
      marginRight: variables.drawerWidth
    },
    contentShift: {
      transition: transitions.create('margin', {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.leavingScreen
      }),
      marginRight: 0
    },
    open: {
      color: palette.secondary.main,
      fontSize: '1.4em'
    },
    drawerTitle: {
      width: '85%',
      display: 'block',
      paddingTop: 15,
      fontSize: '1em',
      float: 'right',
      fontFamily: 'Prompt'
    },
    titleContainer: {
      width: '90%',
      borderBottom: `1px solid ${palette.grey[300]}`,
      marginLeft: unit * 2,
      marginBottom: 18
    },
    drawerContent: {
      display: 'block',
      width: '100%'
    },
    iconButton: {
      margin: 2,
      padding: 10
    },
    whiteBackground: {
      backgroundColor: 'white'
    },
    iconWrapper: {
      width: 50,
      justifyContent: 'center',
      textAlign: 'center'
    },
    icon: {
      fontSize: '2.5em',
      color: palette.primary.main
    },
    rounded: {
      borderRadius: '50%',
      borderWidth: '2px',
      border: `1px solid ${palette.grey[500]}`,
      minWidth: '40px',
      minHeight: '40px',
      height: '40px',
      width: '40px'
    },
    actionIcon: {
      margin: '-1px 5px',
      fontSize: '2.2em'
    },
    miniButton: {
      width: '22px',
      height: '22px',
      minWidth: '22px',
      minHeight: '22px',
      margin: '.5px 7px 0 0',
      '& span': {
        fontSize: '1.1em'
      }
    },
    largeIcon: {
      '& span': {
        fontSize: '1.3em'
      }
    },
    topSection: {
      backgroundColor: 'white',
      margin: `${unit * 4}px ${unit * 6}px`,
      padding: '.5em 0',
      boxShadow: 'none',
      boxSizing: 'border-box',
      borderRadius: unit * 1.5,
      border: `1px solid ${palette.grey[300]}`,
      '&:before': {
        display: 'none'
      }
    },
    blueTop: {
      borderTop: `8px solid ${palette.blue.main}`,
    },
    tealTop: {
      borderTop: `8px solid ${palette.teal.main}`,
    },
    cyanTop: {
      borderTop: `8px solid ${palette.cyan.main}`,
    },
    innerSection: {
      margin: `0 0 ${unit * 3}px 0`
    },
    innerContainer: {
      width: '100%',
      margin: '0 auto',
      maxWidth: variables.maxWidth,
      alignItems: 'stretch',
      display: 'flex'
    },
    childrenContainer: {
      padding: '0 1em'
    },
    heading: {
      color: palette.grey[1000],
      fontFamily: 'Prompt, sans-serif',
      fontSize: '1.2em',
      fontWeight: 400
    },
    subHeading: {
      fontSize: '.9em',
      fontWeight: 400,
      fontFamily: 'Prompt, sans-serif',
      margin: '-4px 0 0 8px'
    },
    innerHeading: {
      color: palette.primary.dark,
      fontWeight: 500,
      fontFamily: 'Prompt, sans-serif',
      fontSize: '1.1em',
      marginBottom: 5
    },
    headingContainer: {
      textAlign: 'left',
      alignContent: 'center',
      margin: `${unit}px ${unit * 4}px 0`
    },
    onlyHeading: {
      marginBottom: 0,
      fontSize: '1.2em'
    },
    actionButton: {
      fontSize: '.8em',
      letterSpacing: '.1em',
      marginRight: unit
    },
    actionContainer: {
      marginRight: unit * 2,
      display: 'flex'
    },
    expansionPanelSummary: {
      paddingLeft: '1em'
    },
    linearProgress: {
      position: 'absolute',
      left: '20px',
      right: '20px',
      bottom: '20px',
      opacity: .6,
      transition: transitions.create('opacity', {
        easing: transitions.easing.easeIn,
        duration: 500
      })
    },
    hidden: {
      opacity: 0
    },
    expanded: {},
    outerContainer: {
      paddingLeft: unit
    }
  });
