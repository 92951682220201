import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ palette }) => ({
  title: {
    fontSize: '1.7em',
    fontWeight: 300,
    fontFamily: 'Prompt',
    color: palette.grey[800]
  }
});

const Title = ({ title, classes, className }) =>
  <Typography
    className={classNames(classes.title, className)}
    variant="h1"
  >{title}</Typography>

export default withStyles(styles)(Title);
