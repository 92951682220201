import {
  PROTOCOLS
} from 'constants/index';
import { store } from 'persistentStore';
import { logEvent } from '@medapps/amplitude-transactions';
import { getGroups, getGroupProtocols } from 'Models/Protocol';
import {
  saveProtocol as saveProtocolAction,
  deleteProtocol as deletePrtocolAction,
  searchProtocols as searchProtocolsAction
} from 'Models/Protocol';
import errorHandler from 'utils/errorHandler';
import { popSnackBar } from '../../components/Snackbar/actions';
import { ProtocolModel, Step } from '../../Models/Protocol/models';
import uuid from 'uuid/v4';

const { dispatch, getState } = store;

const setState = state =>
  dispatch({
    type: PROTOCOLS.SET_STATE,
    state
  });

const setValue = ({ key, value }) =>
  dispatch({
    type: PROTOCOLS.SET_VALUE,
    key,
    value
  });

const toggleSections = () =>
  dispatch({
    type: PROTOCOLS.TOGGLE_SECTIONS
  });

const fetchGroupProtocols = groupId =>
  getGroupProtocols(groupId)
    .then(protocols =>
      dispatch({
        type: PROTOCOLS.RECIEVE_GROUPS_PROTOCOLS,
        protocols,
        groupId
      }));

const fetchAllProtocols = () => {
  const { protocols } = getState();
  const groups = protocols.groups.reduce((acc, group) => acc.concat(group.id), []);
  return Promise.all(groups.map(
    group =>
      fetchGroupProtocols(group)
        .then(res => res)
        .catch(errorHandler)
  ))
};

const dropSection = (dragIndex, dropIndex) =>
  dispatch({
    type: PROTOCOLS.DROP_SECTION,
    dragIndex,
    dropIndex
  });

const dropCallToAction = (dragIndex, dropIndex) =>
  dispatch({
    type: PROTOCOLS.DROP_CALL,
    dragIndex,
    dropIndex
  });

const toggleCreateOrEditCallToAction = (call, callCount) => {
  const { protocols } = getState();
  const isOpen = protocols.showCreateOrEditCallToAction;
  !isOpen && fetchAllProtocols();
  return dispatch({
    type: PROTOCOLS.TOGGLE_CREATE_OR_EDIT_CALL_TO_ACTION,
    call,
    callCount
  });
}

const updateSelectedCallToAction = callToAction =>
  dispatch({
    type: PROTOCOLS.UPDATE_SELECTED_CALL_TO_ACTION,
    callToAction
  });

const updateSelectedAlert = alert =>
  dispatch({
    type: PROTOCOLS.UPDATE_SELECTED_ALERT,
    alert
  });

const updateSelectedTimer = timer =>
  dispatch({
    type: PROTOCOLS.UPDATE_SELECTED_TIMER,
    timer
  });

const saveCallToAction = callToAction =>
  dispatch({
    type: PROTOCOLS.SAVE_CALL_TO_ACTION,
    callToAction
  });

const saveAlert = alert =>
  dispatch({
    type: PROTOCOLS.SAVE_ALERT,
    alert
  });

const saveTimer = timer =>
  dispatch({
    type: PROTOCOLS.SAVE_TIMER,
    timer
  });

const saveProtocol = (protocol, published) => {
  setValue({ key: 'loading', value: true });
  return saveProtocolAction(protocol.set('published', published))
    .then(({ id }) => {
      dispatch({
        type: PROTOCOLS.SAVE_PROTOCOL,
        published,
        id
      });
      return popSnackBar('Protocol successfully saved');
    })
    .catch(err => {
      setValue({ key: 'loading', value: false });
      errorHandler(err);
      return popSnackBar('There was a problem saving the protocol', 'error');
    })
};

const searchProtocols = event => {
  const searchTerm = event.target.value;
  setValue({ key: 'searchTerm', value: searchTerm });
  if (searchTerm.length > 2) {
    const { protocols } = getState();
    const { searchIndex, groups } = protocols;
    setValue({ key: 'searchIndex', value: searchIndex + 1 });
    return searchProtocolsAction(searchTerm, groups)
      .then(returnedGroups => {
        const { protocols } = getState();
        if (protocols.searchIndex === searchIndex + 1) {
          setValue({ key: 'protocolsFromSearch', value: returnedGroups });
        }
      })
  }
};

const deleteProtocol = (id, groupId, title) =>
  deletePrtocolAction(id)
    .then(() => {
      fetchGroupProtocols(groupId);
      logEvent('Protocol Deleted', {
        protocolName: title,
        protocolType: groupId,
      });
      return popSnackBar('Protocol successfully deleted');
    })
    .catch(err => {
      errorHandler(err);
      fetchGroupProtocols(groupId);
      return popSnackBar('There was a problem deleting the protocol', 'error');
    });

const toggleCreateOrEditProtocol = (protocol, groupId) =>
  dispatch({
    type: PROTOCOLS.TOGGLE_CREATE_OR_EDIT_PROTOCOL,
    protocol: protocol || ProtocolModel({
      groupId: groupId
    })
  });

const updateProtocol = (key, value) =>
  dispatch({
    type: PROTOCOLS.UPDATE_PROTOCOL,
    key,
    value
  });

const selectStep = step =>
  dispatch({
    type: PROTOCOLS.SELECT_STEP,
    step
  });

const updateSelectedStep = (key, value) =>
  dispatch({
    type: PROTOCOLS.UPDATE_SELECTED_STEP,
    key,
    value
  });

const updateSelectedStepSection = (id, key, value) =>
  dispatch({
    type: PROTOCOLS.UPDATE_SELECTED_STEP_SECTION,
    key,
    value,
    id
  });

const addSectionToSelectedStep = (sectionType, emptySection) =>
  dispatch({
    type: PROTOCOLS.ADD_SECTION_TO_SELECTED_STEP,
    sectionType,
    emptySection
  });

const addNewStep = stepCount => {
  const unique = uuid();
  const step = Step({ id: unique, order: stepCount + 1 })
  return dispatch({
    type: PROTOCOLS.ADD_NEW_STEP,
    step
  });
};

const reorderSteps = steps =>
  dispatch({
    type: PROTOCOLS.REORDER_STEPS,
    steps
  });

const removeSectionFromSelectedStep = id =>
  dispatch({
    type: PROTOCOLS.REMOVE_SECTION_FROM_SELECTED_STEP,
    id
  });

const removeCallToActionFromSelectedStep = id =>
  dispatch({
    type: PROTOCOLS.REMOVE_CALL_TO_ACTION_FROM_SELECTED_STEP,
    id
  });

const removeStep = id =>
  dispatch({
    type: PROTOCOLS.REMOVE_STEP,
    id
  });

const toggleEditAlert = alert =>
  dispatch({
    type: PROTOCOLS.TOGGLE_EDIT_ALERT,
    alert
  });

const toggleEditTimer = timer =>
  dispatch({
    type: PROTOCOLS.TOGGLE_EDIT_TIMER,
    timer
  });

const fetchGroups = () => {
  setValue({ loading: true });
  return getGroups()
    .then(groups =>
      dispatch({
        type: PROTOCOLS.RECIEVE_GROUPS,
        groups
      }))
};

export {
  setValue,
  toggleSections,
  setState,
  fetchGroups,
  fetchGroupProtocols,
  toggleCreateOrEditProtocol,
  updateProtocol,
  selectStep,
  updateSelectedStep,
  updateSelectedStepSection,
  addSectionToSelectedStep,
  removeSectionFromSelectedStep,
  addNewStep,
  removeStep,
  toggleCreateOrEditCallToAction,
  saveProtocol,
  deleteProtocol,
  updateSelectedCallToAction,
  saveCallToAction,
  removeCallToActionFromSelectedStep,
  toggleEditAlert,
  updateSelectedAlert,
  saveAlert,
  searchProtocols,
  toggleEditTimer,
  saveTimer,
  updateSelectedTimer,
  reorderSteps,
  dropSection,
  dropCallToAction
}
