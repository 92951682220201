import React, { useEffect } from 'react';
import Template from 'components/Template';
import Page from 'components/Section/Page';
import TitleBar from 'components/Section/TitleBar';
import { fetchGroups } from './actions';
import Loader from 'components/Loading';
import Group from './Group';
import { sort } from 'utils/sorting'
import { searchProtocols } from './actions';

const Protocols =
  ({
     showSections,
     groups,
     loading,
     searchTerm
   }) => {
    useEffect(() => {
      fetchGroups();
    }, []);
    return <Template>
      <Page
        search={{
          searchFn: searchProtocols,
          label: 'Search Protocols...',
          searchTerm
        }}
      >
        <TitleBar
          title="Protocols"
        />
        {loading &&
        <Loader/> ||
        <React.Fragment>
          {
            groups &&
            sort(
              groups
                .toIndexedSeq()
                .toArray(), 'id'
            ).map(({ id, title, color, protocols }) =>
              <Group searchTerm={searchTerm} key={id} {...{ id, title, color, protocols }}/>
            )
          }
        </React.Fragment>
        }
      </Page>
    </Template>
  }

export default Protocols;
