import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import Profile from 'modules/App/Profile';
import Grid from '@material-ui/core/Grid/Grid';

const SideSection = withStyles(styles)(
  ({}) =>
    <Grid item container justify="flex-end" md={4}>
      <Profile/>
    </Grid>
);

export {
  SideSection
}
