import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Navigation from './SideNav';
import React from 'react';
import { logEvent } from '@medapps/amplitude-transactions';
import { withStyles } from '@material-ui/core';
import styles from '../styles';

const isSelected = (name, locationArray = []) =>
  locationArray.includes(name);

const View =
  ({
     classes,
     locationArray,
   }) =>
    <Navigation>
      <Link
        className={classNames(
          classes.routeLink,
          { [classes.selected]: isSelected('protocols', locationArray) }
        )}
        to="/protocols"
        onClick={() => {
          logEvent('Screen View', {
            screenType: 'Dashboard',
            screenName: 'Protocols',
          });
        }}
      >
        Protocols
      </Link>
      <Link
        className={classNames(
          classes.routeLink,
          { [classes.selected]: isSelected('users', locationArray) }
        )}
        to="/users"
        onClick={() => {
          logEvent('Screen View', {
            screenType: 'Dashboard',
            screenName: 'Users',
          });
        }}
        selected={isSelected('users', locationArray)}
      >
        Users
      </Link>
      <Link
        className={classNames(
          classes.routeLink,
          { [classes.selected]: isSelected('pages', locationArray) }
        )}
        to="/pages"
        onClick={() => {
          logEvent('Screen View', {
            screenType: 'Dashboard',
            screenName: 'Pages',
          });
        }}
        selected={isSelected('pages', locationArray)}
      >
        Pages
      </Link>
    </Navigation>

export default withStyles(styles)(View);
