import { DrawerComponent as RichText } from './Sections/TextSection';
import { DrawerComponent as CallForHelp } from './Sections/EmergencyCallInformCart';
import { DrawerComponent as Alert } from './Sections/Alert';
import { DrawerComponent as Timer } from './Sections/Timer';

export default ([
  {
    type: 'richText',
    name: 'Rich Text',
    drawerComponent: RichText,
    emptySection: {}
  },
  {
    type: 'callForHelp',
    name: 'Call For Help',
    drawerComponent: CallForHelp,
    emptySection: {}
  },
  {
    type: 'alert',
    name: 'Alert',
    drawerComponent: Alert,
    emptySection: {
      style: 'redOutlined',
      content: 'Click To Edit Alert...'
    }
  },
  {
    type: 'timer',
    name: 'Timer',
    drawerComponent: Timer,
    emptySection: {
      style: 'redFilled',
      content: 'Click To Edit Timer...',
      countDown: 0
    }
  }
])
