import React from 'react';
import api from 'utils/api';
import { apiUrl } from 'app-constants';
import { PageModel } from './models';
import { normalizePageForSaving } from './normalize';

const getPages = () =>
  api.get(`${apiUrl}/pages`)
    .then(result => {
      if (result.data) {
        return result.data.items.map(page => new PageModel(page))
      }
      throw new Error(result.message.message || 'Error saving user');
    });

const addPage = page =>
  api.post(`${apiUrl}/page`, normalizePageForSaving(page))
    .then(result => {
      if (result.data) {
        return result.data;
      }
      throw new Error(result.message || 'Error saving page');
    });

const editPage = page =>
  api.put(`${apiUrl}/page/${page.id}`, normalizePageForSaving(page))
    .then(result => {
      if (result.data) {
        return result.data;
      }
      throw new Error(result || 'Error saving page');
    });

const deletePage = pageId =>
  api.delete(`${apiUrl}/page/${pageId}`)
    .then(result => {
      if (result) {
        return result.status === 200;
      }
      throw new Error(result.message || 'Error deleting page');
    });

const getPage = id =>
  api.get(`${apiUrl}/page/${id}`)
    .then(result => {
      if (result.data) {
        return new new PageModel(result.data)
      }
      throw new Error(result);
    });

export {
  getPages,
  addPage,
  editPage,
  deletePage,
  getPage
};
