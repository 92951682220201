import { Record, OrderedMap } from 'immutable';
import uuid from 'uuid/v4';
import { orderedMapFromArray } from 'utils/immutable';

const CallToAction = new Record({
  id: uuid(),
  title: 'Continue',
  type: 'continue',
  style: 'blueOutlined',
  goTo: 'nextStep',
  goToText: 'Next Step',
  goToStep: null,
  order: 0
});

const Step = new Record({
  id: uuid(),
  title: '',
  callsToAction: orderedMapFromArray([{ id: uuid() }], CallToAction),
  sections: OrderedMap({}),
  order: 1
});

const ProtocolModel = new Record({
  id: null,
  title: '',
  groupId: '',
  stepCount: 0,
  steps: orderedMapFromArray([{ id: uuid() }], Step),
  published: false,
  createdAt: 0,
  updatedAt: 0
}, 'ProtocolModel');

const GroupModel = new Record({
  id: '',
  title: '',
  color: '',
  protocols: []
});

const Section = new Record({
  id: uuid(),
  type: 'fallback',
  content: '',
  style: '',
  order: 1,
  duration: null
});

export {
  ProtocolModel,
  GroupModel,
  Step,
  Section,
  CallToAction
}
