import Button from '@material-ui/core/Button/Button';
import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ palette }) => ({
  button: {
    padding: '12px 70px',
    borderWidth: '2px',
    height: '50px',
    borderColor: palette.secondary.main
  }
});

const PrimaryActionButton = ({ classes, variant = 'outlined', icon, className, text, ...rest  }) =>
  <Button
    variant={variant}
    color="secondary"
    className={classNames(classes.button, className)}
    {...rest}
  >
    {icon && icon}
    {text || null}
  </Button>

export default withStyles(styles)(PrimaryActionButton);
