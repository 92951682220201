import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles';
import { hideSnackBar } from './actions';
import classNames from 'classnames';

const SnackBarView = ({
  open, message = 'Success!', classes, variant
}) => (
  <Snackbar
    className={classNames(
      classes.snack,
      { [classes[variant]]: variant }
    )}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    open={open}
    autoHideDuration={6000}
    onClose={() => hideSnackBar()}
    message={(
      <span id="client-snackbar" className={classes.message}>
        <FontAwesomeIcon
          icon={['fas', variant === 'error' && 'times-circle' || 'check-circle']}
          className={classes.icon}
        />
        {message}
      </span>
     )}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={classes.close}
        onClick={() => hideSnackBar('')}
      >
        <Icon>close</Icon>
      </IconButton>
    ]}
  />
);

SnackBarView.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SnackBarView);
