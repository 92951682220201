import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames';

const styles = {
  root: {
    flexGrow: 1
  },
  transparent: {
    opacity: '.7'
  }
};

const LinearIndeterminate = ({ classes, className, color='secondary', transparent }) => (
  <div className={classNames(
    classes.root,
    className,
    { [classes.transparent]: transparent }
  )}>
    <LinearProgress color={color}/>
  </div>
);

LinearIndeterminate.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LinearIndeterminate);
