import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Icon from 'components/Icons/Icon';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const styles = ({ palette, variables }) => ({
  stepperTitle: {
    textAlign: 'center',
    color: 'white',
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
    fontSize: '2em',
    marginTop: 43,
    lineHeight: '0px'
  },
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: variables.maxWidth,
    margin: '0 auto',
    padding: '0'
  },
  stepLabel: {
    color: palette.grey[100],
    fontSize: '.9em',
    fontFamily: 'Nunito, sans-serif'
  },
  activeStepLabel: {
    color: `${palette.grey[100]}!important`
  },
  stepCircle: {
    cursor: 'pointer',
    marginRight: 10
  },
  fullWidth: {
    width: '100%'
  },
  dark: {
    color: `${palette.grey[900]}!important`
  },
  stepNumber: {
    position: 'absolute',
    left: 15,
    fontSize: '1.2em',
    color: palette.primary.light
  },
  stepButton: {
    color: palette.primary.light,
    textTransform: 'none',
    padding: '25px 50px 25px 0'
  },
  active: {
    color: palette.secondary.main
  },
  success: {
    color: palette.success
  },
  stepButtonLabel: {
    fontSize: '1.2em'
  },
  container: {
    margin: '0 30px',
    background: '#fff',
    position: 'relative',
    '&:before': {
      top: 1,
      // borderColor: 'transparent transparent transparent white',
      border: 'solid transparent',
      borderWidth: 43,
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '100%',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      zIndex: 1
    },
    '&:hover:before': {
      // borderColor: `transparent transparent transparent ${palette.grey[200]}`,
    },
    '&:after': {
      top: 0,
      border: 'solid transparent',
      // borderColor: `transparent transparent transparent ${palette.grey[400]}`,
      // borderWidth: 44,
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '100%',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderColor: 'rgba(136, 183, 213, 0)',
      borderLeftColor: '#88b7d5',
      borderWidth: 30,
      marginTop: -30
    }
  }
});

const StepperComponent = ({ close, steps, classes, title, underSteps }) =>
  <div className={classes.fullWidth}>
    {title && <Typography
      className={classes.stepperTitle}
      variant="h1"
    >{title}</Typography>}
    <Stepper
      activeStep={steps.activeStep}
      connector={null}
      classes={{
        root: classNames(classes.stepper, { [classes.dark]: underSteps })
      }}
    >
      {steps.labels.map(step => (
        <Step
          key={step.label}
          classes={{}}
        >
          <StepLabel
            classes={{
              label: classNames(classes.stepLabel, { [classes.dark]: underSteps }),
              active: classes.activeStepLabel,
              completed: classNames(classes.activeStepLabel)
            }}
            StepIconComponent={
              ({ active, completed }) =>
                <div className={classes.container}>
                  <Button
                    onClick={() =>
                      ((!steps.skipAhead && steps.highestStepCompleted >= step.step) || steps.skipAhead) &&
                      steps.progressStep(step.step)}
                    className={classNames(classes.stepButton,
                      {
                        [classes.active]: active,
                        [classes.success]: completed
                      }
                    )}
                  >
                    <Icon
                      className={classNames(
                        classes.stepCircle
                      )}
                      icon="circle"
                      color={active && 'secondary' || completed && 'success' || 'lightPrimary'}
                      reset
                      onClick={() =>
                        ((!steps.skipAhead && steps.highestStepCompleted >= step.step) || steps.skipAhead) &&
                        steps.progressStep(step.step)}
                      size="3x"
                      iconType={'fal'}
                    />
                    <span
                      className={classNames(classes.stepNumber,
                        {
                          [classes.active]: active,
                          [classes.success]: completed
                        }
                      )}>{step.step + 1}</span>
                    <span className={classes.stepButtonLabel}>{step.label}</span></Button>
                </div>
            }
          >
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  </div>;

export default withStyles(styles)(StepperComponent);
